import React, {useState, useEffect} from 'react';
import axios from '../../axios';
import './login.scss';
import logo from './../../White.png';
import decode from "jwt-decode";
import { connect } from "react-redux";
import {
    setAuth,
    setUserID,
  } from "../../redux/Functions/actions";

const Login = (props) => {



    const [userData, adduserdata] = useState({});
    const [errors, addErrors] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [visibility, setvisibility] = useState(false);




    const useremail = e => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
  
        adduserdata(ud);
    }

    const password = e => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        adduserdata(ud);
    }


const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.email && userData.pass) {
        axios.post('/login', userData)
            .then((res) => {
                if (res.data.token) {
                    addErrors(false);
                    window.localStorage.setItem('cd', res.data.token);
                    const userdata = decode(res.data.token);
                    props.setUserID(userdata?.id, userdata?.role);
                    props.setAuth(res?.data?.token);
                    window.location = "/";
                } else {
                    addErrors(res.data.error);
                }
            })
            .catch(err => {
                console.log('error incoming', err);
                setLoginErrorMessage(err?.response?.data?.error);
                // window.localStorage.removeItem('cd');
                // window.location = "/login";
            })
    } else {
        addErrors(true);
    }
}

return(
    <div className='login'>
        <div className='row text-danger pl-5'>
          
        </div>
        <div className='login-logo'><img src={logo} alt='Logo' width='300px'/></div>
        <div className='login-wraper'>
            <form onSubmit={handleSubmit}>
                <div className='input-field'>
                    <input type='email' placeholder='Email Address' onChange={useremail}/>
                </div>
                <div className='input-field'>

                <input type={visibility == false ? 'password' : 'text'} placeholder='Password' onChange={password} />
                    <i className={visibility == false ? " fa fa-eye password-icon" : " fa fa-eye-slash password-icon"} onClick={()=> {setvisibility(!visibility)}} />
                </div>
               

                <button type='submit'>Login</button>
            </form>
            <div className='text-center text-danger'>
                {loginErrorMessage}
            </div>
        </div>


    </div>
)
};

const mapStateToProps = (state) => {
    return {
      auth: state.data.auth,
      user_id: state.data.user_id,
      user_role: state.data.user_role,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setUserID: (id, role) => dispatch(setUserID(id, role)),
      setAuth: (auth) => dispatch(setAuth(auth)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Login);



