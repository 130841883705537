import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const EditDataTypes = (props) => {

    const [datatypes, adddatatypes] = useState({});

    const datatypesName = e => {
        console.log(e)
        const ud = Object.assign({}, datatypes);
        ud.name = e.target.value
        adddatatypes(ud);
    }

    const datatypesType = e => {
        console.log(e)
        const ud = Object.assign({}, datatypes);
        ud.type = e.target.value
        adddatatypes(ud);
    }

    useEffect(() => {

        axios.get('data-types/id?id=' + props.match.params.id)
            .then((data) => {
                adddatatypes(data.data)
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put('data-types/', datatypes)
            .then(
                (data) => {
                    console.log(data.data);
                    toast.success('Data Type has been edited successfully!')
                    window.history.back();
                }
            )
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
    }
    console.log(datatypes)
    return (
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Edit datatypes</h1></div>
            <form onSubmit={handleSubmit}>

                <input type='text' required
                       title="This field is required" placeholder='Datatypes Name' onChange={datatypesName} value={datatypes.name}></input>
                <input type='text' required
                       title="This field is required" placeholder='Datatypes Type' onChange={datatypesType} value={datatypes.type}></input>


                <button type='submit' className="buttonAction">Edit datatype</button>
            </form>
        </div>

    )
}
export default EditDataTypes;
