import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "./header.scss";

const SubMenu = ({ item, index, handleMenu, activeMenu }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  const pathName = window.location.pathname;
  console.log("jyyy", index);
  const [active, setActive] = useState(-1);
  const handleOnClick = (index) => {
    setActive(index);
  };

  return (
      <>
        <div>
          <ul className={item.className}>
          <Link to={item.path} onClick={item.subNav && showSubnav}>
            <li
                // className="active_link"
                onClick={() => {
                  handleMenu(index);
                }}
                className={activeMenu === index ? "active-sidebar" : "menuitem"}
            >
              
                {item.title}
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                    ? item.iconClosed
                    : null}
            </li>
            </Link>

       
          </ul>
        </div>
        <div className="subnav_items">
          <ul>
            {" "}
            {subnav &&
            item.subNav.map((item, index) => {
              return (
                <Link to={item.path} key={index}>
                  <li
                      // className={item.className}
                      className="active_link1"
                      // onClick={() => {
                        // handleOnClick(index);
                      // }}
                      // className={active === index ? "active-sidebar" : "menuitem"}
                  >
                   
                      {item.title}
                  </li>
                  </Link>

              );
            })}
          </ul>
        </div>
      </>
  );
};

export default SubMenu;
