import React, { useEffect, useState } from "react";
import axios from "../../axios";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import "./points.scss";

import Modal from "react-modal";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import closeIcn from "../../Images/close.svg";
import ListingFeatures from "../listing_features/listingFeatures";
import ListingPointFeatures from "../points_features/listingPointFeatures";
import ListingPointSubCategory from "../points_features/listingPointSubCategory";
import WorkingTime from "../workingTime/workingTime";



// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const EditPoint = (props) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [userdata, adduserdata] = useState([]);
  const [userroles, adduserroles] = useState([]);
  const [languages, addlanguages] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [mapValidation, setMapValidation] = useState("");
  const [mapData, setMapData] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [catData, setcatData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [ndryshoImazhin, setndryshoImazhin] = useState(false);
  const [isShopEdit, setisShopEdit] = useState(false);
  const [mapState, setMapState] = useState([0, 0]);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  const [modalIsOpen5, setIsOpen5] = React.useState(false);
  const [modalIsOpenDelete, setModalIsOpenDelete] = React.useState(false);
  const [modalIsOpenAudio6, setIsOpenAudio6] = React.useState(false);
  const [modalIsOpenImage7, setIsOpenImage7] = React.useState(false);
  const [center, setCenter] = useState([0, 0]);
  const [located, setLocated] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [countries, setCountries] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [imgError, setImgError] = useState("");
  const [audio, setAudio] = useState(null);
  const [image, setImage] = useState(null);
  // const [cId, setCID] = useState('');
  const [modalState, setModalState] = useState(false);
  const [singleData, setSingleData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [descriptionData, setDescriptionData] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [successCall, setSuccessCall] = useState("");
  const [successDeletedMsg, setSuccessDeletedMsg] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const { idParams } = useParams();
  const [imagee, setImagee] = useState();
  const [descriptonPutID, setDescriptionPutID] = useState("");
  const [audioPutID, setAudioPutID] = useState("");
  const [imagePutId, setImagePutID] = useState("");
  const [features, setFeatures] = useState([]);
  const [previousCenter, setPreviousCenter] = useState(null);
  let subtitle;
  let cId = "";

  function FlyMapTo() {
    const map = useMap();

    useEffect(() => {
      if (previousCenter !== center) {
        map.flyTo(center);
        setPreviousCenter(center);
      }
    }, [center, map, previousCenter]);

    return null
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  const idd = props.match.params.id;
  console.log("idd",idd)
  // const idd = idParams;

  async function openConfirmModal(id) {
    console.log("idddddddddd", id);
    await setDeleteId(id);

    setModalIsOpenDelete(true);
  }

  function closeConfirmModal() {
    setModalIsOpenDelete(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal2() {
    setIsOpen2(true);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  function openModal3() {
    setIsOpen3(true);
  }

  function closeModal3() {
    setIsOpen3(false);
  }

  function openModal4() {
    setIsOpen4(true);
  }

  function closeModal4() {
    setIsOpen4(false);
    setImagee();
  }

  async function openModal5(id) {
    setDescriptionPutID(id);
    await getSingleContent(id);
    await setIsOpen5(true);
  }

  function closeModal5() {
    setIsOpen5(false);
  }

  async function openModal6(id) {
    setAudioPutID(id);
    await getSingleAudio(id);
    await setIsOpenAudio6(true);
  }

  function closeModal6() {
    setIsOpenAudio6(false);
  }

  async function openModal7(id) {
    setImagePutID(id);
    await getSingleImage(id);
    await setIsOpenImage7(true);
  }

  function closeModal7() {
    setIsOpenImage7(false);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  const uploadAudioFile = async (e) => {
    e.preventDefault();

    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an audio.");
      setDisabled(true);
      return;
    }

    if (!file.name.match(/\.(mp3|wav|flac)$/)) {
      setImgError("Please select a valid image. (mp3, wav, flac)");
      setDisabled(true);
      return;
    }

    if (file && file.name.match(/\.(mp3|wav|flac)$/)) {
      await setAudio(file);
      setDisabled(false);
    }
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImagee(URL.createObjectURL(e.target.files[0]));
    }

    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an image.");
      setDisabled(true);
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgError("Please select a valid image. (jpg, jpeg, png)");
      setDisabled(true);
      return false;
    }

    if (file && file.name.match(/\.(jpg|jpeg|png)$/)) {
      await setImage(file);
      setDisabled(false);
    }
  };

  const uploadImage = async (event) => {

    event.preventDefault();
    let languageValue = event.target[1].value;
    const imageUpload = new FormData();
    imageUpload.append("image", image, image?.name);
    imageUpload.append("lang", languageValue);
   
    try {
      let response = await axios.post(`point-data/image/${idd}`, imageUpload);
      console.log(response);
      //   setSuccessCall("Success");
      toast.success("Image uploaded Successfully!");
      getImageData();
      setImagee()
      // setTimeout(() => {
        closeModal4();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const uploadContent = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    let content = event.target.content.value;
    try {
      let response = await axios.post(`point-data/description/${idd}`, {
        lang: languageValue,
        content: content,
      });
      //   setSuccessCall("Success");
      toast.success("Content uploaded Successfully!");
      getDescriptionData();
      // setTimeout(() => {
        closeModal2();
      // }, 1000);
      console.log(response);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const uploadAudio = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    const audioUpload = new FormData();
    audioUpload.append("audio", audio, audio?.name);
    audioUpload.append("lang", languageValue);
    try {
      let response = await axios.post(`point-data/audio/${idd}`, audioUpload);
      console.log(response);
      //   setSuccessCall("Success");
      toast.success("Audio uploaded Successfully!");
      getAudioData();
      // setTimeout(() => {
        closeModal3();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  // let id = props?.match.params.id;

  let map;

  const latLongFunc = async (e) => {
    const ud = Object.assign({}, userdata);
    ud.latitude = e[0];
    ud.longitude = e[1];
    await adduserdata(ud);
  };

  // console.log('map data yyyyyyyyyyy', mapData)

  const latitude = (e) => {
    const ud = Object.assign({}, mapData);
    ud.latitude = e.target.value;
    setMapData(ud);
  };
  const longitude = (e) => {
    const ud = Object.assign({}, mapData);
    ud.longitude = e.target.value;
    setMapData(ud);
  };

  // console.log('leaflet map')

  const getPoint = async () => {
    try {
      let response = await axios.get(`/point/id?id=${idd}`);
      adduserdata(response?.data);
    } catch (error) {
      // toast.error('Something went wrong!')
      console.log(error);
    }
  };

  const getFeaturesData = () => {
    axios.get('listing-features/all').then(
      res => {
          setFeatures(res?.data);
      }
    ).catch(err => { console.log('Errori', err) });
  }

  useEffect( () => {
     getImageData();
     getDescriptionData();
     getAudioData();
     getCities();
     getCategories();
     getSubCategories();
     getPoint();
     getCities();
     getCategories();
     getSubCategories();
     getAllCountries();
     getFeaturesData();
    axios
      .get("languages/all")
      .then((data) => {
        addlanguages(data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, [idd, singleData]);

  // const defValues = () => {
  //     const ud = Object.assign({}, userdata);
  //     ud.auth = 'initial-auth';
  //     ud.mobileauth = 'initial-auth';
  //     adduserdata(ud);
  // }

  const pointName = (e) => {
    const ud = Object.assign({}, userdata);
    ud.name = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const def_lang = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.def_lang = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const country = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.country_id = e.target.value;
    console.log("ZZZZZZZZZZZZZZZZZZ", ud.country);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const city = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.city_id = e.target.value;

    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const category = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.category_id = e.target.value;
    console.log(ud);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const subCategory = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.subcategory_id = e.target.value;
    console.log(ud);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const active = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.active = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const visibility = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.is_visible = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const langID = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.lang_id = e.target.value;
    adduserdata(ud);
    console.log("seeme", userdata);
  };
  // const buttonState = () => {
  //     setDisableButton(!disableButton);
  // }

  // console.log('heheh', disableButton);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("point", {
        user_id: 1,
        ...userdata,
      })
      .then((res) => {
        toast.success("Point has been edited successfully!");
        console.log(res);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        console.log(error);
      });
  };
  // console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz' , Object.keys(userdata).length );
  // console.log(languages);
  //
  // let objectLength = Object.keys(userdata).length;

  const submitCoordinates = async (event) => {
    event.preventDefault();

    try {
      let response = await axios.put("point/location", {
        id: 4,
        ...mapData,
      });
      setMapValidation("Saved");
      console.log(response);
      // setTimeout(() => {
        setMapValidation("");
      // }, 3000);
    } catch (error) {
      setValidationError("Failed");
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      let response = await axios.get("point-categories/all");
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCities = async () => {
    try {
      let response = await axios.get("cities/all");
      setCities(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  function MyComponent() {
    // useEffect(()=>{
    //     map.locate();
    // },[])
    map = useMapEvents({
      // whenReady: () => {
      //     console.log('loaded')
      //     locationCenter();
      // },
      click: async (e) => {
        let i = await map.mouseEventToLatLng(e.originalEvent);
        await setMapState([Number(i.lat), Number(i.lng)]);
        console.log(map);
        await latLongFunc([Number(i.lat), Number(i.lng)]);
        // await latitude(i.lat);
        //  latitude(i.lat).then(()=>longitude(i.lng));
        // setTimeout(()=>latitude(i.lat), 105);
        console.log("shop data inside click", userdata);
        // map.locate();
      },
      locationfound: async (location) => {
        if (userdata?.longitude === 0 && userdata?.latitude === 0) {
          latLongFunc([location.latlng.lat, location.latlng.lng]);
          await map.panTo([
            Number(userdata?.latitude),
            Number(userdata?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
        }
        console.log("location found:", [
          location.latlng.lat,
          location.latlng.lng,
        ]);
        if (userdata != "") {
          console.log("po hin te shopi", [
            Number(userdata?.latitude),
            Number(location?.longitude),
          ]);
          console.log("centraaaa", [
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
          await map.panTo([
            Number(userdata?.latitude),
            Number(userdata?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
        } else {
          console.log("po hin te location default");
          await map.panTo([
            Number(location.latlng.lat),
            Number(location.latlng.lng, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(location.latlng.lat),
            Number(location.latlng.lng),
          ]);
        }
        // );
        // setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
        setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
        setLocated(true);
        map.stopLocate();
        // console.log(mapState)
      },
    });
    if (located == false) {
      map.locate();
    }
    return null;
  }

  const getAllCountries = async () => {
    try {
      let response = await axios.get("countries/all");
      setCountries(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSubCategories = async () => {
    try {
      let response = await axios.get("point-subcategories/all");
      setSubCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAudioData = async () => {
    try {
      let response = await axios.get(`point-data/audio/${idd}`);
      console.log("", response?.data);
      setAudioData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDescriptionData = async () => {
    try {
      let response = await axios.get(`point-data/description/${idd}`);
      setDescriptionData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getImageData = async () => {
    try {
      let response = await axios.get(`point-data/image/${idd}`);
      setImageData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleContent = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [singleAudio, setSingleAudio] = useState([]);

  const getSingleAudio = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleImage = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      console.log("response...................", response.data);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const editContent = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    let content = event.target.content.value;

    try {
      let response = await axios.put(`point-data/description/${descriptonPutID}`, {
        lang: languageValue,
        content: content,
      });
      //   setSuccessCall("Success");
      getDescriptionData();
      getSingleContent(cId);
      toast.success("Content has been edited successfully!");
      // setTimeout(() => {
        closeModal5();
      // }, 1000);
      console.log(response);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const editAudio = async (event) => {
    event.preventDefault();

    let languageValue = event.target[1].value;
    const audioUpload = new FormData();

    audioUpload.append("audio", audio, audio?.name);
    audioUpload.append("lang", languageValue);

    console.log("content content content", audioUpload);

    try {
      let response = await axios.put(`point-data/audio/${audioPutID}`, audioUpload);
      getSingleAudio(cId);
      getAudioData();
      //   setSuccessCall("Success");
      toast.success("Audio has been edited successfully!");
      console.log(response);
      // setTimeout(() => {
        closeModal6();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const editImage = async (event) => {
    event.preventDefault();

    let languageValue = event.target[1].value;
    const imageUpload = new FormData();

    imageUpload.append("image", image, image?.name);
    imageUpload.append("lang", languageValue);
    try {
      let response = await axios.put(`point-data/image/${imagePutId}`, imageUpload);

      getSingleContent(cId);
      getImageData();
      //   setSuccessCall("Success");
      toast.success("Image has been edited successfully!");
      // setTimeout(() => {
        closeModal7();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const deleteContent = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`point-data/?id=${id}`);

      getDescriptionData();
      getSingleContent(cId);
      // setTimeout(() => {
        closeModal5();
      // }, 1000);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAudio = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`point-data/?id=${id}`);
      getSingleAudio(cId);
      getAudioData();
      console.log(response);
      toast.success("Audio has been deleted successfully!");
      // setTimeout(() => {
        closeModal6();
      // }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`point-data/?id=${id}`);
      console.log(response);
      getSingleContent(cId);
      getImageData();
      toast.success("Content has been deleted successfully!");
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  // console.log(userdata.longitude)
  //
  //
  // console.log('heh', subCategories)
  // console.log('hjeeh', categories)
  //
  // console.log('userzzzrz,', userdata)
  //
  //
  // console.log(',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,', singleData)

  let id = "";
  const confirmButton = (event, id) => {
    console.log("delete id ", deleteId);
    console.log("log incoming", id);
    id = id;
    if (id == 1) {
      deleteImage(event, deleteId);
      getImageData();
      getAudioData();
      getDescriptionData();
      setSuccessDeletedMsg("Has been deleted successfully");
      // setTimeout(() => {
        closeConfirmModal();
      // }, 2000);
    } else {
      console.log("nuk u fsih kurgjo");
      // setTimeout(() => {
        closeConfirmModal();
      // }, 1000);
    }
  };

  const getCurrentLocation = (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation([latitude, longitude]);
        setMapState([0,0]);
        setCenter([latitude,longitude])
        const ud = Object.assign({}, userdata);
        ud.latitude = latitude;
        ud.longitude = longitude;
        adduserdata(ud);
      }, (error) => {
        console.error("Error getting location:", error);
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="points p-5 text-left">
      <div className="row">
        <h1>Edit Point</h1>
      </div>
      {/*<button onClick={buttonState} type="btn btn-success button">Add Points</button>*/}
      <div className={"myMap"}>
        <div>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
          {/*<button onClick={openModal} className={'map-button showme'}><i*/}
          {/*    className="fas fa-map-marker-alt"></i><span className={'hideme map-button-txt'}>Add Point Location</span>*/}
          {/*</button>*/}
          <div className="row mb-5">
            <div className="col-md-12">
              <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
                <MyComponent />
                <FlyMapTo />
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {userdata != "" ? (
                  <Marker position={[userdata.latitude, userdata.longitude]}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                ) : mapState != "" ? (
                  <Marker position={[mapState[0], mapState[1]]}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                ) : (
                  ""
                )}
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">

                      <span className="text-center"><strong>Lat: </strong> {userdata.latitude},<strong> Long:</strong>  {userdata.longitude}</span>
                      </div>
                      <button onClick={getCurrentLocation} className="text-light btn btn-success w-50 p-2 my-2">
                      <i className="fas fa-location-arrow"></i>
                      <span className='ms-2'>Get Current Location</span>
                      </button>
      {!isloading ? (
        ""
      ) : (
        <form onSubmit={handleSubmit}>
          <span className="p-2 text-muted">Select Name</span>
          <input
            type="text"
            required
            title="This field is required"
            placeholder="Point name"
            defaultValue={userdata?.name}
            onChange={pointName}
          ></input>
          <span className="p-2 text-muted">Select Category</span>
          <select onChange={category} defaultValue={userdata?.category_id}>
            <option disabled defaultValue={userdata?.category_id} selected>
              Select Category
            </option>
            {categories?.map((el) => {
              return <option value={el.id}>{el.name}</option>;
            })}
          </select>
          <span className="p-2 text-muted">Select Sub-Category</span>
          <select
            onChange={subCategory}
            defaultValue={userdata?.subcategory_id}
          >
            <option disabled defaultValue={userdata?.subcategory_id} selected>
              Select Sub-Category
            </option>
            {subCategories?.map((el) => {
              return <option value={Number(el.id)}>{el.name}</option>;
            })}
          </select>
          <span className="p-2 text-muted">Select City</span>
          <select onChange={city} defaultValue={userdata?.city_id}>
            <option disabled defaultValue={userdata?.city_id} selected>
              Select City
            </option>
            {cities?.map((el) => {
              return <option value={Number(el.id)}>{el.name}</option>;
            })}
          </select>
          <span className="p-2 text-muted">Select Country</span>
          <select onChange={country} defaultValue={userdata?.country_id}>
            <option disabled selected>
              Select Country
            </option>
            {countries?.map((el) => {
              return <option value={Number(el.id)}>{el.name}</option>;
            })}
          </select>
          {/*<select onChange={def_lang}>*/}
          {/*    <option disabled selected>Select Language</option>*/}
          {/*    {languages?.map((el) => {*/}
          {/*        return (<option value={Number(el.id)}>{el.name}</option>)*/}
          {/*    })}*/}
          {/*</select>*/}
          <span className="p-2 text-muted">Select Status</span>
          <select defaultValue={userdata?.active ? 1 : 0} onChange={active}>
            <option>Select Status</option>
            <option value={0}>Passive</option>
            <option value={1}>Active</option>
          </select>
          <span className="p-2 text-muted">Select Visibility</span>
                  <select className="form-control" defaultValue={userdata?.is_visible ? 1 : 0} onChange={visibility}>
                    <option>Select Visibility</option>
                    <option value={0}>Passive</option>
                    <option value={1}>Active</option>
                  </select>
          <button type="submit" className="edit-point-button w-100">
            Edit Point
          </button>
        </form>
      )}

      <div>
        <div className="row buttons-group">
          <div className="col-md-12 mb-3">
            <button className="btn-plus" onClick={openModal2}>
              <i className="fas fa-plus"></i>
            </button>
            <h2>Content</h2>
            <div className="row">
              <div className="col-md-12">
                {descriptionData?.map((el, i) => {
                  return (
                    <div className="listimi showme">
                      <div className="row">
                        <div className="col-7">{el?.content?.length > 30 ? el?.content?.slice(0, 30) + '...' : el?.content}</div>
                        <div className="col-2">{el?.lang}</div>
                        {/*<div className='col-2'>test</div>*/}
                        {/*<div className='col-2'>test</div>*/}
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-edit"}
                            onClick={() => openModal5(el?.point_data_id)}
                          ></Link>
                        </div>
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-trash"}
                            onClick={() => openConfirmModal(el?.point_data_id)}
                          ></Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <button className="btn-plus" onClick={openModal3}>
              <i className="fas fa-plus"></i>
            </button>
            <h2>Audio</h2>
            <div className="row">
              <div className="col-md-12">
                {audioData?.map((el, i) => {
                  // console.log('hehe audio', el)
                  return (
                    <div className="listimi showme">
                      <div className="row">
                        <div className="col-3">{el?.data_type_name}</div>
                        <div className="col-3">{el?.data_type}</div>
                        <div className="col-3">{el?.lang}</div>
                        {/*<div className='col-2'>test</div>*/}
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-edit"}
                            onClick={() => openModal6(el?.point_data_id)}
                          ></Link>
                        </div>
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-trash"}
                            onClick={() => openConfirmModal(el?.point_data_id)}
                          ></Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <button className="btn-plus" onClick={openModal4}>
              <i className="fas fa-plus"></i>
            </button>
            <h2>Image</h2>
            <div className="row">
              {imageData?.map((el, i) => {
                // console.log('audio audio audio', process.env.REACT_APP_UPLOADS + el.url)
                return (
                  <div className="col-md-4 showme">
                    <img
                      src={process.env.REACT_APP_UPLOADS + el?.url}
                      className="card-img-top image-part"
                      alt="..."
                    />
                    <div className="col-1 hidemee">
                      <Link
                        className={"fa fa-edit"}
                        onClick={() => openModal7(el?.point_data_id)}
                      ></Link>
                    </div>
                    <div className="col-1 hidemee">
                      <Link
                        className={"fa fa-trash"}
                        onClick={() => openConfirmModal(el?.point_data_id)}
                      ></Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
          <ListingPointFeatures pointId={idd} category_id={userdata?.category_id}/>
          <ListingPointSubCategory pointId={idd} category_id={userdata?.category_id}/>
          <WorkingTime pointId={idd}/>
      <div className="d">
        {modalIsOpen5 ? (
          <div className="row modal-table">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              
            {/* EDIT CONTENT MODAL */}
              <Modal
                isOpen={modalIsOpen5}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal5}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  
                  <div className="modal-header">
                    <p>Edit Content</p>
                    <button onClick={() => closeModal5()}><img src={closeIcn} width={"12.5px"} /></button>
                  </div>
               
                  <div className="col-md-12">
                    <form onSubmit={editContent} >
                      <div className="mb-3 form-modal">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Content:
                        </label>
                        <textarea
                          className="form-control"
                          name="content"
                          defaultValue={singleData?.content}
                          id="exampleFormControlTextarea1"
                          title="This field should not be left blank."
                          required
                          rows="3"
                        ></textarea>
                      </div>
                      <p>{imgError}</p>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                          >
                            Options
                          </label>
                        </div>
                        <select
                          className="custom-select"
                          defaultValue={singleData?.lang_id}
                          id="inputGroupSelect01"
                          name="lang_id"
                          title="This field should not be left blank."
                          required
                        >
                          <option
                            name="lang_id"
                            disabled
                            // defaultValue={singleData?.lang_id}
                            selected
                          >
                            Select Language
                          </option>
                          {languages?.map((el) => {
                            return (
                              <option value={Number(el.id)}>{el.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="button-left">
                      <button
                        className="btn btn-outline-secondary submit-button"
                        type="submit"
                        disabled={isDisabled}
                        id="inputGroupFileAddon03"
                      >
                        Edit Content
                      </button>
                      </div>
                      {/* <p className="validation-msg-modal">successCall</p> */}
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}

        {modalIsOpenAudio6 ? (
          <div className="row modal-table">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* EDIT AUDIO MODAL */}
              <Modal
                isOpen={modalIsOpenAudio6}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal6}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  <div className="modal-header">
                    <p>Edit Audio</p>
                    <button onClick={() => closeModal6()}><img src={closeIcn} width={"12.5px"} /></button>
                  </div>
                  <div className="col-md-12">
                    {/* <h1 className="title-part">Edit Audio</h1> */}
                    <div className="audio-player">
                      <audio controls>
                        <source
                          src={process.env.REACT_APP_UPLOADS + singleData?.url}
                          type="audio/ogg"
                        />
                      </audio>
                    </div>
                    <form onSubmit={editAudio}>
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile03"
                          name="audio"
                          onChange={uploadAudioFile}
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          required
                          title="This field should not be left blank."
                        />
                      </div>
                      <p>{imgError}</p>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                          >
                            Options
                          </label>
                        </div>
                        <select
                          className="custom-select"
                          defaultValue={singleData?.lang_id}
                          id="inputGroupSelect01"
                          title="This field should not be left blank."
                          required
                        >
                          <option name="lang" disabled selected>
                            Select Language
                          </option>
                          {languages?.map((el) => {
                            return (
                              <option value={Number(el?.id)}>{el?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <p></p>
                      <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={isDisabled}
                          id="inputGroupFileAddon03"
                        >
                          Upload Audio
                        </button>
                      </div>
                      {/* <p className="validation-msg-modal">{successCall}</p> */}
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}

   
        {modalIsOpenImage7 ? (
          <div className="row audio-part">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* EDIT IMAGE MODAL */}
              <Modal
                isOpen={modalIsOpenImage7}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal7}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  <div className="modal-header">
                    <p>Edit Image</p>
                    <button onClick={() => closeModal7()}><img src={closeIcn} width={"12.5px"} /></button>

                  </div>

                  <div className="col-md-12">
                    {/* <h1 className="title-part">Edit Image</h1> */}
                    <form onSubmit={editImage}>
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile03"
                          name="image"
                          onChange={uploadFile}
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          title="This field should not be left blank."
                          required
                        />
                      </div>
                      <p>{imgError}</p>
                      {/*<div className="input-group mb-3">*/}
                      {/*    <div className="input-group-prepend">*/}
                      {/*        <label className="input-group-text"*/}
                      {/*               htmlFor="inputGroupSelect01">Options</label>*/}
                      {/*    </div>*/}
                      {/*    <select className="custom-select" id="inputGroupSelect01">*/}
                      {/*        <option*/}
                      {/*            name="lang"*/}
                      {/*            disabled*/}
                      {/*            selected>Select Language*/}
                      {/*        </option>*/}
                      {/*        {languages?.map((el) => {*/}
                      {/*            return (<option*/}
                      {/*                value={Number(el.id)}>{el.name}</option>)*/}
                      {/*        })}*/}
                      {/*    </select>*/}
                      {/*</div>*/}
                      <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={false}
                          id="inputGroupFileAddon03"
                        >
                          Upload Image
                        </button>
                      </div>
                      {/* <p className="validation-msg-modal">{successCall}</p> */}
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}

    
        {modalIsOpenDelete ? (
          <div className="row ">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* DELETE MODAL */}
              <Modal
                isOpen={modalIsOpenDelete}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeConfirmModal}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row content-part audio-part">
                  
                  <div className="modal-header">
                    <p>Delete This?</p>
                    <button onClick={() => closeConfirmModal()}><img src={closeIcn} width={"12.5px"} /></button>

                  </div>

                  <div className="col-md-12 delete-modal">
                    {/* <h1>Delete This?</h1> */}
                    <h1></h1>
                    <button
                      className="btn btn-outline-secondary confirm-button"
                      type="submit"
                      onClick={(event) => confirmButton(event, 1)}
                      id="inputGroupFileAddon03"
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-outline-secondary confirm-button"
                      type="submit"
                      onClick={(event) => confirmButton(event, 0)}
                      id="inputGroupFileAddon03"
                    >
                      No
                    </button>
                    {/* <p className="validation-msg-modal">{successDeletedMsg}</p> */}
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="row modal-table">
        <div className="col-md-12">
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

          {/* ADD CONTENT MODAL */}
          <Modal
            isOpen={modalIsOpen2}
            // onAfterOpen={afterOpenModal2}
            onRequestClose={closeModal2}
            className="mymodal"
            contentLabel="Example Modal"
          >
            <div className="row audio-part">

              <div className="modal-header">
                <p>Content</p>
                <button onClick={() => closeModal2()}><img src={closeIcn} width={"12.5px"} /></button>
              </div>
              <div className="col-md-12">
                {/* <h1 className="title-part">Content</h1> */}
                <form onSubmit={uploadContent}>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Example textarea
                    </label>
                    <textarea
                      className="form-control"
                      name="content"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                  <p>{imgError}</p>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <label
                        className="input-group-text"
                        htmlFor="inputGroupSelect01"
                      >
                        Options
                      </label>
                    </div>
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      title="This field should not be left blank."
                      required
                    >
                      <option name="lang" disabled selected>
                        Select Language
                      </option>
                      {languages?.map((el) => {
                        return <option value={Number(el.id)}>{el.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="button-left">
                    <button
                      className="btn btn-outline-secondary submit-button"
                      type="submit"
                      id="inputGroupFileAddon03"
                    >
                      Upload
                    </button>
                    </div>
                  {/* <p className="validation-msg-modal">{successCall}</p> */}
                </form>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <div>
        <div className="row modal-table">
          <div className="col-md-12">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

            {/* UPLOAD AUDIO MODAL */}
            <Modal
              isOpen={modalIsOpen3}
              // onAfterOpen={afterOpenModal2}
              onRequestClose={closeModal3}
              className="mymodal"
              contentLabel="Example Modal"
            >
              <div className="row audio-part">

                <div className="modal-header">
                  <p>Upload Audio</p>
                  <button onClick={() => closeModal3()}><img src={closeIcn} width={"12.5px"} /></button>
                </div>
                <div className="col-md-12">
                  {/* <h1 className="title-part">Upload Audio</h1> */}
                  <form onSubmit={uploadAudio}>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile03"
                        name="audio"
                        onChange={uploadAudioFile}
                        aria-describedby="inputGroupFileAddon03"
                        aria-label="Upload"
                        title="This field should not be left blank."
                        required
                      />
                    </div>
                    <p>{imgError}</p>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect01"
                        >
                          Options
                        </label>
                      </div>
                      <select
                        className="custom-select"
                        id="inputGroupSelect01"
                        title="This field should not be left blank."
                        required
                      >
                        <option name="lang" disabled selected>
                          Select Language
                        </option>
                        {languages?.map((el) => {
                          return (
                            <option value={Number(el.id)}>{el.name}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="button-left">
                      <button
                        className="btn btn-outline-secondary submit-button"
                        disabled={isDisabled}
                        type="submit"
                        id="inputGroupFileAddon03"
                      >
                        Upload
                      </button>
                    </div>
                    {/* <p className="validation-msg-modal">{successCall}</p> */}
                  </form>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className="row audio-part">
          <div className="col-md-12">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

            {/*UPLOAD IMAGE MODAL  */}
            <Modal
              isOpen={modalIsOpen4}
              // onAfterOpen={afterOpenModal2}
              onRequestClose={closeModal4}
              className="mymodal"
              contentLabel="Example Modal"
            >
              <div className="row audio-part">

                <div className="modal-header">
                  <p>Upload Image</p>
                  <button onClick={() => closeModal4()}><img src={closeIcn} width={"12.5px"} /></button>

                </div>

                <div className="col-md-6">
                    <div className="text-center">
                      {imagee === undefined  ? (
                        <img
                          className="img-fluid"
                          src="https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2l0eXxlbnwwfHwwfHw%3D&w=800&q=80"
                          alt=""
                          // width="200" height="200"
                        />
                      ) : (
                        <img src={imagee}   
                        width="200" height="200" 
                        />
                      )}
                    </div>
                  </div>
                <div className="col-md-6">
                  {/* <h1 className="title-part">Upload Image</h1> */}
                  <form onSubmit={uploadImage}>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile03"
                        name="image"
                        onChange={uploadFile}
                        aria-describedby="inputGroupFileAddon03"
                        aria-label="Upload"
                        title="This field should not be left blank."
                        required
                      />
                    </div>
                    <p>{imgError}</p>
                    {/*<div className="input-group mb-3">*/}
                    {/*    <div className="input-group-prepend">*/}
                    {/*        <label className="input-group-text"*/}
                    {/*               htmlFor="inputGroupSelect01">Options</label>*/}
                    {/*    </div>*/}
                    {/*    <select className="custom-select" id="inputGroupSelect01">*/}
                    {/*        <option*/}
                    {/*            name="lang"*/}
                    {/*            disabled*/}
                    {/*            selected>Select Language*/}
                    {/*        </option>*/}
                    {/*        {languages?.map((el) => {*/}
                    {/*            return (<option value={Number(el?.id)}>{el?.name}</option>)*/}
                    {/*        })}*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    <div className="button-left">
                      <button
                        className="btn btn-outline-secondary submit-button"
                        disabled={isDisabled}
                        type="submit"
                        id="inputGroupFileAddon03"
                      >
                        Upload
                      </button>
                    </div>
                    {/* <p className="validation-msg-modal">{successCall}</p> */}
                  </form>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditPoint;
