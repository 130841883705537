import {useState} from "react";

const Search = (props) => {

    let {data, filtered, setPageNumber} = props

    const searcher = async (text) => {
        let searchText = await text?.target.value.toLowerCase();
        let filteredUser = data?.filter((dataCallBack) => {
            console.log('users incoming', dataCallBack)
            if(dataCallBack?.name) {
                return (dataCallBack?.name.toLowerCase().includes(searchText))
                // setPageNumber(0);
            }else if(dataCallBack?.lable_name) {
               return (dataCallBack?.lable_name.toLowerCase().includes(searchText))
                // setPageNumber(0);
            } else if(dataCallBack?.role_name) {
                return (dataCallBack?.role_name.toLowerCase().includes(searchText))
                // setPageNumber(0);/
            } else  {
                return (dataCallBack?.full_name.toLowerCase().includes(searchText))
                // setPageNumber(0);
            }
        })
        filtered(filteredUser)
        setPageNumber(0)
    }

    return (
        <div className="col-6 col-sm-5 mb-3 ">
            {/* <label>Search</label> */}
            <input
                type="text"
                onChange={searcher}
                className="form-control search-input"
                placeholder="Search..."
                aria-label="Search"
            />
        </div>
    )
}


export default Search;