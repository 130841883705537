import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const AddLabelLang = (props) => {

    const [labellang, addlabellang] = useState({});
    const [languages, addlanguages] = useState({});
    const [labels, addlabels] = useState({});
    const [isloading, setisloading] = useState(false);


    useEffect(() => {
        axios.get('languages/all')
            .then(data => {
                addlanguages(data.data)
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
        axios.get('labels/all')
            .then((data) => {
                addlabels(data.data);
                setisloading(true)
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
    }, [])

    const labelId = e => {
        const ud = Object.assign({}, labellang);
        ud.lable_id = e.target.value
        addlabellang(ud);
        console.log('seeme', labellang)
    }
    const content = e => {
        // console.log(e)
        const ud = Object.assign({}, labellang);
        ud.content = e.target.value
        addlabellang(ud);
        console.log('seeme', labellang)
    }
    const langID = e => {
        // console.log(e)
        const ud = Object.assign({}, labellang);
        ud.lang_id = e.target.value
        addlabellang(ud);
        console.log('seeme', labellang)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('label-lang', labellang)
            .then(data => {
                console.log(data);
                toast.success('Language Label has been added successfully!')
                window.history.back();
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });

    }
    console.log(languages);
    console.log(labels);

    return (
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Add labels</h1></div>
            {!isloading ? '' :
                <form onSubmit={handleSubmit}>
                    <select onChange={labelId}>
                        <option disabled selected>Select</option>
                        {labels?.map((el) => {
                            return (<option value={el.id}>{el.name}</option>)
                        })}

                    </select>
                    <input type='text' required
                           title="This field is required" placeholder='Content' onChange={content}></input>
                    <select required
                            title="This field is required" onChange={langID}>
                        <option value="" disabled selected>Select</option>
                        {languages?.map((el) => {
                            return <option value={el?.id}>{el?.name}</option>
                        })}

                    </select>
                    <button type='submit' className="buttonAction">Add Label</button>
                </form>
            }
        </div>

    )
}
export default AddLabelLang;
