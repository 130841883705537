import React, { useEffect, useState } from 'react';
import axios from "../../axios";
import './dashboard.scss';
import PieChart from './barChart';
import { toast } from 'react-toastify';

const Dashboard = () => {
    const [statistics, setStatisitics] = useState([]);

    useEffect(() => {
        axios.get('referal-sources/all').then((res) => {
            setStatisitics(res?.data);
        }).catch((err) => {
            toast.error('Something went wrong!')
        });
    }, [])

return(
    <div className='dashboard p-5'>
         <div className='page-title'>
                <h1>Dashboard</h1> <br/>
            </div>
            <div className="row">
        <div className="col-md-6 mx-auto">
        <PieChart stats={statistics}/>
        </div>

            </div>
    </div>
)

}

export default Dashboard;