import React, {useState, useEffect} from 'react';
import './events.scss';
import axios from '../../axios.js'
import Search from "../Search/Search.js";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader.js";
import FeaturesModal from "./pointFeaturesModal.js";
import DeletePointFeatureModal from "./deletePointFeaturesModal.js";
import { connect } from "react-redux";
const ListingPointFeatures = (props) => {
    const [features, setFeatures] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);
    const [deleteId, setDeleteId] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const pagesVisited = pageNumber * productsPerPage;
    const pageCount = Math.ceil(filtered?.length / productsPerPage);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    };

    const getData = () => {
      axios.get(`point-features/all/${props?.pointId}`).then(
        res => {
            setFeatures(res?.data);
            setFiltered(res?.data)
            setIsLoading(false)
        }
      ).catch(err => { console.log('Errori', err) });
    }

   
    useEffect(() => {
      getData();

    }, []);
  

    const handleModalDelete = (data) => {
      setIsOpenDelete(true);
      setDeleteId(data);
    };

    const handleModalAdd = () => {
      setIsOpen(true);
      setModalType("add");
    };

    return (
      <>
      {isOpen === true && <FeaturesModal pointId={Number(props?.pointId)} getData={()=>getData()} type={modalType} data={dataToModal} cat_id={props?.category_id} isOpen={isOpen} handleModalClose={() => { setIsOpen(false); setDataToModal(null) }} user_id={props?.user_id} />}
      {isOpenDelete === true && <DeletePointFeatureModal isOpen={isOpenDelete} getData={()=>getData()} handleModalClose={() => { setIsOpenDelete(false)}} user_id={props?.user_id} eventData={deleteId} />}
        <div className='points p-5 text-left'>
            <div className='d-flex justify-content-end fisearch-add'>
            {/* <Search data={features} filtered={setFiltered} setPageNumber={setPageNumber}/> */}
            <button className='btn btn-info' onClick={() => handleModalAdd()}>
                <i className='fa fa-plus'/> Add new 
            </button>
            </div>
            <h1 className='mb-3'>Features</h1>
            <div className='row'>
            <div className='row list-description mb-2'>
                       
                            <div className='col'>Point</div>
                            <div className='col'>Feature</div>
                            <div className='col'>Delete</div>
                            </div>
                {
                    isLoading ? <Loader/> :
                       filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map(filtered => {
                       
                    return (
                    <div className='listimi showme' key={filtered?.id}>
                        <div className='row '>
                            <div className='col'>{filtered?.point?.name} </div>
                            <div className='col'>{filtered?.listing_feature?.name} </div>
                            <div className='col'><i className='fa fa-trash text-danger' onClick={() => handleModalDelete(filtered)}></i></div>
                            
                        </div>
                    </div>)
                })}
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(features.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }

                </div>
            </div>
        </div>
      </>

    )

}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingPointFeatures);