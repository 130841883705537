import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './events.scss';
import axios from '../../axios'
import Search from "../Search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader";
import EventsModal from "./eventsModal.js";
import DeleteEventModal from "./deleteEventModal";
import { connect } from "react-redux";
import { AddButton, TableStyle, PaginationStyle } from "../../styles/MainLayout.js";
const Events = (props) => {

    const [users, setusers] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [point, setPoint] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);
    const [deleteId, setDeleteId] = useState();
    const [cities, setCities] = useState();
    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filtered?.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };

    const getData = () => {
      axios.get('events/all').then(
        res => {
            setusers(res?.data);
            setFiltered(res?.data)
            setIsLoading(false)
        }
      ).catch(err => { console.log('Errori', err) });
    }

    const getPointData = () => {
      axios.get('/point/all').then(
          data => {
              setPoint(data?.data)
              console.log("pointData",data?.data);
          }
      ).catch(err => { console.log('Errori', err) });
  }
    const getCities = () => {
      axios.get('/cities/all').then(
          data => {
              setCities(data?.data)
              console.log("pointData",data?.data);
          }
      ).catch(err => { console.log('Errori', err) });
  }

    useEffect(() => {
      getData();
      getPointData();
      getCities();
    }, []);

    // useEffect(() => {
    //     axios.get('events/all').then((res) => {
    //         setusers(res?.data);
    //         setFiltered(res?.data)
    //         setIsLoading(false)
    //     }).catch((err) => {
    //         console.log(err)
    //     });
    //     axios.get('/point/all').then(
    //       data => {
    //           setPoint(data?.data)
    //           console.log("pointData",data?.data);
    //       }
    //   ).catch(err => { console.log('Errori', err) });
    // }, [])

    const handleModalEdit = (data) => {
      setIsOpen(true);
      setModalType("edit");
      setDataToModal(data);
    };

    const handleModalDelete = (data) => {
      setIsOpenDelete(true);
      setDeleteId(data);
    };

    const handleModalAdd = () => {
      setIsOpen(true);
      setModalType("add");
    };

    const getTypeLabel = (type) => {
      switch (type) {
        case 2:
          return "Specific";
        case 3:
          return "Festival";
        default:
          return "General";
      }
    };

    return (
      <>
      {isOpen === true && <EventsModal getData={()=>getData()} type={modalType} data={dataToModal} isOpen={isOpen} handleModalClose={() => { setIsOpen(false); setDataToModal(null) }} user_id={props?.user_id} />}
      {isOpenDelete === true && <DeleteEventModal isOpen={isOpenDelete} getData={()=>getData()} handleModalClose={() => { setIsOpenDelete(false)}} user_id={props?.user_id} eventData={deleteId} />}
        <div className='points p-5 text-left'>
            <div className='page-title'>
                <h1>Events</h1> <br/>
            </div>
            <div className='row search-add'>
            <Search data={users} filtered={setFiltered} setPageNumber={setPageNumber}/>
            <button className='btn btn-info' onClick={() => handleModalAdd()}>
                Add new 
            </button>
            </div>
            <div className='row'>
            <div className='row list-description mb-2'>
                            {/*<div className='col-1'>{filtered.id} </div>*/}
                            <div className='col'>Event Name</div>
                            <div className='col'>City Name</div>
                            {/* <div className='col'>Point Name</div> */}
                            <div className='col'>Description</div>
                            <div className='col'>Start Time</div>
                            <div className='col'>End Time</div>
                            <div className='col'>Type</div>
                            <div className='col'>Edit</div>
                            <div className='col'>Event Data</div>
                            <div className='col'>Delete</div>
                            </div>
                {
                    isLoading ? <Loader/> :
                        displayUsers = filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map(filtered => {
                       
                    return (<div className='listimi showme' key={filtered?.id}>
                        <div className='row '>
                            {/*<div className='col-1'>{filtered.id} </div>*/}
                            <div className='col'>{filtered?.name} </div>
                            <div className='col'>{cities?.filter((element)=>(element?.id==filtered?.city_id))[0]?.name} </div>
                            <div className='col'>{filtered?.description?.length > 30 ? filtered?.description?.slice(0, 30) + '...' : filtered?.description} </div>
                            <div className='col'>{filtered?.start_time?.slice(0, 10) + "  " + filtered?.start_time?.slice(11, 16)}</div>
                            <div className='col'>{filtered?.end_time?.slice(0, 10) + "  " + filtered?.end_time?.slice(11, 16)}</div>
                            <div className='col'>{getTypeLabel(filtered?.type)}</div>
                            {props?.user_id === filtered?.user_id ? 
                            <>
                            <div className='col'><i className='fa fa-edit' onClick={() => handleModalEdit(filtered)}></i></div>
                            <div className='col'><Link className="text-warning"
                            to={'/edit-event/' + filtered?.id}>
                          <i className='fa fa-edit'/>
                        </Link></div>
                              </>
                            : 
                            <>
                            <div className='col'></div>
                            <div className='col'></div>
                              </>
                            }
                            <div className='col'><i className='fa fa-trash text-danger' onClick={() => handleModalDelete(filtered)}></i></div>
                            
                        </div>
                    </div>)
                })}
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(users.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }
                       {/* <AddButton collapse={props?.sidebarCollapse} className={props?.sidebarCollapse !== "collapsed" ? "notCollapsed" : "addButtonNotCollapsed"}>
          <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5" onClick={() => handleModalAdd()}></i>
        </AddButton> */}
                </div>
            </div>
        </div>
      </>

    )

}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSideBar: (data) => dispatch(setSideBar(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);