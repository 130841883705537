import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const AddPointSubCategory = (props) => {

    const [pointsubcategory, addpointsubcategory] = useState({});
    const [categories, addcategories] = useState({});
    const [labels, addlabels] = useState({});
    const [isloading, setisloading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        axios.get('point-categories/all').then(data => {
            addcategories(data.data);
            setisloading(true);
        }).catch(err => console.log(err));
        // axios.get('labels/all').then((data) => {addlabels(data.data); setisloading(true)}).catch(err =>  console.log(err));
    }, [])

    const categoryID = e => {
        const ud = Object.assign({}, pointsubcategory);
        ud.category_id = e.target.value
        addpointsubcategory(ud);
        // console.log('seeme',pointsubcategory)
    }
    const name = e => {
        // console.log(e)
        const ud = Object.assign({}, pointsubcategory);
        ud.name = e.target.value
        addpointsubcategory(ud);
        // console.log('seeme',pointsubcategory)
    }
    const isActive = e => {
        // console.log(e)
        const ud = Object.assign({}, pointsubcategory);
        ud.active = e.target.value
        addpointsubcategory(ud);
        // console.log('seeme',pointsubcategory)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if(!submitting) {
            setSubmitting(true);
        axios.post('point-subcategories', pointsubcategory)
            .then(data => {
                toast.success('Point Subcategory has been added successfully!')
                console.log(data);
                window.history.back();
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            }).finally(() => {
                setSubmitting(false);
              })}

    }
    console.log(categories);
    console.log(labels);

    return (
        <div className='point_subcategories p-5 text-left'>
            <div className='row'><h1>Add Sub-Category</h1></div>
            {!isloading ? '' :
                <form onSubmit={handleSubmit}>

                    <select required
                            title="This field is required" onChange={categoryID}>
                        <option value="" disabled selected>Select</option>
                        {categories?.map((el) => {
                            return <option value={el.id}>{el.name}</option>
                        })}

                    </select>
                    <input type='text' required
                           title="This field is required" placeholder='name' onChange={name}></input>
                    <select required
                            title="This field is required" onChange={isActive}>
                        <option value="" disabled selected>Select</option>
                        <option value={0}>Passive</option>
                        <option value={1}>Active</option>


                    </select>
                    <button type='submit' className="buttonAction" disabled={submitting}>Add</button>
                </form>
            }
        </div>

    )
}
export default AddPointSubCategory;
