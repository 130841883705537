import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Transactions.scss';
import axios from '../../axios'
import Search from "../Search/Search";
import ReactPaginate from "react-paginate";
import {toast} from "react-toastify";


const Transactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };



    useEffect(() => {

        axios.get('transactions/all').then((res) => {
            setTransactions(res?.data);
            setFiltered(res?.data)
            setIsLoading(false);
        }).catch((err) => {
            toast.error('Something went wrong!')
            console.log(err)
        });
    }, [])

    return (
        <div className='transactions p-5 text-left'>
            <div className='page-title'>
                <h1>Transactions</h1> <br/>
            </div>
            <Search data={transactions} filtered={setFiltered} setPageNumber={setPageNumber}/>
            <div className='row'>
                {/* <Link to='/labels/add' className='myButton'>Add New Transaction</Link> */}
            </div>
            <div className='row w-100 '>
                                    <div className='row headeri p-2 mt-3'>
                                        {/*<div className='col-1'>{filtered.id} </div>*/}
                                        <div className="col-2">User Name</div>
                                        <div className='col-2'>City</div>
                                        <div className='col-2'>Date</div>
                                        <div className='col-2'>Price </div>
                                        <div className="col-2">Total</div>
                                        <div className='col-2'>Currency </div>
                                        {/* <div className='col-1 hideme'><Link className={'fa fa-edit'}
                                                                            to={'/labels/edit/' + filtered.id}></Link></div>
                                        <div className='col-1 hideme'><Link className={'fa fa-trash'}
                                                                            to={'/labels/delete/' + filtered.id}></Link></div> */}
                                    </div>
                {
                    displayUsers = filtered?.slice
                    (pagesVisited, pagesVisited + productsPerPage)?.map
                    (filtered => {
                        console.log(filtered)
                            return (<>
                                    <div className='listimet p-3' key={filtered.id}>
                                    <div className='row '>
                                        {/*<div className='col-1'>{filtered.id} </div>*/}
                                        <div className="col-2">{filtered.user_full_name}</div>
                                        <div className='col-2'>{filtered.city_name} </div>
                                        <div className='col-2'>{filtered.date?.slice(0, 10)} </div>
                                        <div className='col-2'>{filtered.price + filtered.thesign} </div>
                                        <div className="col-2">{filtered?.total + filtered?.thesign}</div>
                                        <div className='col-2'>{filtered.code} </div>
                                        {/* <div className='col-1 hideme'><Link className={'fa fa-edit'}
                                                                            to={'/labels/edit/' + filtered.id}></Link></div>
                                        <div className='col-1 hideme'><Link className={'fa fa-trash'}
                                                                            to={'/labels/delete/' + filtered.id}></Link></div> */}
                                    </div>
                                </div>
                                </>
                            )
                        }
                    )
                }
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(transactions.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Transactions;