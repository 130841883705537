import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './label_languages.scss';
import axios from '../../axios'
import Search from "../Search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader";
import {toast} from "react-toastify";


const LabelLanguages = () => {
    const [labels, setlabels] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    useEffect(() => {
        axios.get('label-lang/all').then((res) => {
            setlabels(res?.data);
            setFiltered(res?.data)
            setIsLoading(false)
        }).catch((err) => {
            toast.error('Something went wrong!')
            console.log(err)
        });
        getAllLanguages();
    }, [])

    console.log('labels', labels)

    const filterByDropDown = (text) => {
        let searchText = text?.target.value.toLowerCase();
        console.log('search text', searchText)
        let filteredUser = labels?.filter((filterByStatus) => {
            if (searchText == 'all') {
                return filterByStatus
            }
            return (filterByStatus?.lang_name.toLowerCase() === searchText.toLowerCase())
        })
        setFiltered(filteredUser)
        setPageNumber(0);
    }


    const getAllLanguages = async () => {
        try {
            let response = await axios.get('languages/all')
            setLanguages(response?.data);
        } catch (error) {
            toast.error('Something went wrong!')
            console.log(error)
        }
    }

    return (
        <div className='label_languages p-5 text-left'>
            <div className='page-title'>
                <h1>Label Language Management</h1> <br/>
            </div>
            <div className="row align-items-center">
                {/*<div className="col-md-6">*/}
                <Search data={labels} filtered={setFiltered} setPageNumber={setPageNumber}/>
                {/*</div>*/}
                <div className="col-md-4 mb-5">
                    <label>Language:</label>
                    <select onChange={filterByDropDown}
                            className="form-control">
                        <option disabled selected>Select Language</option>
                        {
                            languages?.map((el, i) => {
                                return (
                                    <option value={el?.lang_name}>{el?.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-md-2 mb-3">
                    <label>Nr. Language</label>
                    <p>{filtered?.length}</p>
                </div>
            </div>
            <div className='row search-add justify-content-end'>
                <Link to='/labellang/add' className='myButton'>Add New Label Language</Link>
            </div>
            <div className='row'>
            <div className='row list-description mb-2'>
                                    <div className='col-1'>Id</div>
                                    <div className='col-3'>Label Name</div>
                                    <div className='col-3'>Content</div>
                                    <div className='col-3'>Language</div>
                                    <div className='col-1 hideme'>Edit</div>
                                    <div className='col-1 hideme'>Delete</div>
                                </div>
                {
                    isLoading ? <Loader/> :
                        displayUsers = filtered?.slice
                        (pagesVisited, pagesVisited + productsPerPage)?.map
                        (filtered => {
                                console.log('here: ', filtered)
                                return (
                                    <div className='listimi showme'>
                                        <div className='row '>
                                            <div className='col-1'>{filtered.id} </div>
                                            <div className='col-3 text-break'>{filtered.lable_name} </div>
                                            <div className='col-3'>{filtered?.content?.length > 30 ? filtered?.content?.slice(0, 30) + '...' : filtered?.content} </div>
                                            <div className='col-3'>{filtered.lang_name} </div>
                                            <div className='col-1 hideme'><Link className={'fa fa-edit'}
                                                                                to={'/labellang/edit/' + filtered.id}></Link>
                                            </div>
                                            <div className='col-1 hideme'><Link className={'fa fa-trash'}
                                                                                to={'/labellang/delete/' + filtered.id}></Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        )
                }
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(labels.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default LabelLanguages;