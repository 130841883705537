import React from 'react';
import axios from '../../axios';
import {
    ModalStyle, 
    ModalMainButtons, ModalButtonsPosition, ModalWholePage, 
} from "../../styles/ModalStyle"
import { FaExclamationTriangle } from "react-icons/fa";
import ModalPortal from "../../portal/ModalPortal";
import { toast } from "react-toastify";

const ApproveModal = (props) => {
    console.log("props",props)
    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .put(`/point/activatePoint/${props?.userData.id}`)
            .then((res) => {
                toast.success("User has been activated successfully!");
                props?.getData();
                props?.handleModalClose();
            })
            .catch((error) => {
                toast.error("Something went wrong!");
                console.log(error);
            });
    };


    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle deletePoint>
                        <div className="text-center">
                            <FaExclamationTriangle className='display-3 text-danger mb-5' />
                            <h3>Activate User</h3>
                            <h5 className='text-secondary'>You're going to activate Point "{props?.userData?.name}"<br /> Are you sure?</h5>
                        </div>
                        <ModalButtonsPosition className='justify-content-between mt-5'>
                            <ModalMainButtons closeDeleteButton onClick={() => props?.handleModalClose()}>No.</ModalMainButtons>
                            <ModalMainButtons type="submit" deleteModal onClick={(e) => handleSubmit(e)}>Yes, Approve! </ModalMainButtons>
                        </ModalButtonsPosition>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }
}

export default ApproveModal;