import React, {useEffect,useState} from 'react';
import { toast } from "react-toastify";
import axios from '../../axios';

const DeleteLanguages = (props) => {

const [languages, addlanguages] = useState({});

useEffect(()=>{

    axios.get('languages/id?id=' + props.match.params.id)
         .then(    (data) => {
            addlanguages(data.data)
        })
         .catch(err =>  console.log(err))
},[])

const handleSubmit = (e) => {
    e.preventDefault();
    axios.delete('languages/?id='+ languages.id )
        .then(
            (data) => {
                toast.success("Language has been deleted successfully!");
               window.history.back();
            }
        )
        .catch(err => console.log(err));
}
    console.log(languages)
    return(
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Delete Languages</h1></div>
            <div className='row'>By clicking delete button you agree to delete languages with name {languages.name}</div>
            <form onSubmit={handleSubmit}>
          
                <input type='text' placeholder='languages Name'  value={languages.name} disabled></input>
                <input type='text' placeholder='languages Code'  value={languages.code} disabled></input>
                <input type='text' placeholder='Phone Prefix'  value={languages.active ? 'Active': 'Passive'} disabled></input>
            
                <button type='submit'>Delete Language</button>
            </form>
        </div>

    )
}
export default DeleteLanguages;
