import Modal from "react-modal";
import React, {useEffect, useState} from 'react';
import axios from '../../axios';



Modal.setAppElement('#root');



const EditModal = (props) => {


    //
    console.log(props)
    //
    let subtitle;









    return (
        <>
            {

            }
            <div className="row audio-part">
                <div className="col-md-12">
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
                    <Modal
                        isOpen={props?.isOpen}
                        // onAfterOpen={afterOpenModal2}
                        onRequestClose={props?.onReq}
                        style={customElements}
                        className="mymodal"
                        contentLabel="Example Modal"
                    >
                        <div className="row audio-part">
                            <div className="col-md-12">
                                <h1 className="title-part">Upload Image</h1>
                                <form >
                                    <div className="input-group mb-3">
                                        <input type="file"
                                               className="form-control"
                                               id="inputGroupFile03"
                                               name="image"
                                               // onChange={uploadFile}
                                               aria-describedby="inputGroupFileAddon03"
                                               aria-label="Upload"
                                        />
                                    </div>
                                    {/*<p>{imgError}</p>*/}
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text"
                                                   htmlFor="inputGroupSelect01">Options</label>
                                        </div>
                                        <select className="custom-select" id="inputGroupSelect01">
                                            <option
                                                name="lang"
                                                disabled
                                                selected>Select Language
                                            </option>
                                            {/*{languages?.map((el) => {*/}
                                            {/*    return (<option value={Number(el.id)}>{el.name}</option>)*/}
                                            {/*})}*/}
                                        </select>
                                    </div>
                                    <button className="btn btn-outline-secondary submit-button" type="submit"
                                            id="inputGroupFileAddon03">Upload
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default EditModal;