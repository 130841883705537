import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './cities.scss';
import axios from '../../axios'
import Search from "../Search/Search";
import ReactPaginate from 'react-paginate';
import Loader from "../Loader/Loader";
import {toast} from "react-toastify";


const Cities = () => {
    const [cities, setCities] = useState([]);
    console.log('qytetet', cities);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    useEffect(() => {
        axios.get('cities/all').then((res) => {
            setCities(res?.data);
            setFiltered(res?.data)
            setIsLoading(false);
        }).catch((err) => {
            toast.error('Something went wrong!')
            console.log(err)
        });
    }, [])

    console.log('qytetet-pas', cities);


    return (
        <div className='cities p-5 text-left'>
            <div className='page-title'>
                <h1>Cities Management</h1> <br/>
            </div>
            <div className='row search-add'>
            <Search data={cities} filtered={setFiltered} setPageNumber={setPageNumber}/>
                <Link to='/cities/add' className='myButton'>Add New City</Link>
            </div>
            <div className='row'>
            <div className='row list-description mb-2'>
                                    <div className='col-1'>City Id</div>
                                    <div className='col-9'>Name</div>
                                    <div className='col-1 hideme'>Edit</div>
                                    <div className='col-1 hideme'>Delete</div>
                                </div>
                {isLoading ? <Loader /> :
                    displayUsers = filtered?.slice
                (pagesVisited, pagesVisited + productsPerPage)?.map
                (filtered => {
                        return (
                            <div className='listimi showme' key={filtered.id}>
                                <div className='row'>
                                    <div className='col-1'>{filtered.id} </div>
                                    <div className='col-9'>{filtered.name} </div>
                                    <div className='col-1 hideme'><Link className={'fa fa-edit'}
                                                                        to={'/cities/edit/' + filtered.id}></Link></div>
                                    <div className='col-1 hideme'><Link className={'fa fa-trash'}
                                                                        to={'/cities/delete/' + filtered.id}></Link></div>
                                </div>
                            </div>
                        )
                    }
                )
                }
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(cities.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Cities;