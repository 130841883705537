import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './point_categories.scss';
import axios from '../../axios'
import Search from "../Search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader";
import {toast} from "react-toastify";


const PointCategories = () => {
    const [pointcategories, setpointcategories] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };

    useEffect(() => {

        axios.get('point-categories/all').then((res) => {
            setpointcategories(res?.data);
            setFiltered(res?.data)
            setIsLoading(false)
        }).catch((err) => {
            toast.error('Something went wrong!')
            console.log(err)
        });
    }, [])

    return (
        <div className='point-categories p-5 text-left'>
            <div className='page-title'>
                <h1>Point Categories Management</h1> <br/>
            </div>
            <div className='row search-add'>
            <Search data={pointcategories} filtered={setFiltered} setPageNumber={setPageNumber}/>
                <Link to='/pointcategories/add' className='myButton'>Add New Category</Link>
            </div>
            <div className='row'>
            <div className='row list-description mb-2'>
                                    <div className='col-1'>Id</div>
                                    <div className='col-5'>Name</div>
                                    <div className='col-3'>Status</div>
                                    <div className='col-1 hideme'>Edit</div>
                                    {/* <div className='col-1 hideme'>Delete</div> */}
                                </div>
                {
                    isLoading ? <Loader/> :
                        displayUsers = filtered?.slice
                    (pagesVisited, pagesVisited + productsPerPage)?.map
                    (filtered => {
                    return (<div className='listimi showme' key={filtered.id}>
                            <div className='row '>
                                <div className='col-1'>{filtered.id} </div>
                                <div className='col-5'>{filtered.name} </div>
                                <div className='col-3'>{filtered.active ? 'Active' : 'Passive'} </div>
                                <div className='col-1 hideme'><Link className={'fa fa-edit'}
                                                                    to={'/pointcategories/edit/' + filtered.id}></Link></div>
                                {/* <div className='col-1 hideme'><Link className={'fa fa-trash'}
                                                                    to={'/pointcategories/delete/' + filtered.id}></Link>
                                </div> */}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(pointcategories.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default PointCategories;