import React, {useEffect,useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const EditLable = (props) => {

const [labels, addlabels] = useState({});

const labelsName = e => {
    console.log(e)
    const ud = Object.assign({}, labels);
    ud.name = e.target.value
    addlabels(ud);
}


useEffect(()=>{
    axios.get('labels/id?id=' + props.match.params.id)
         .then(    (data) => {
            addlabels(data.data)
        })
         .catch(err =>  {
             toast.error('Something went wrong!')
             console.log(err)
         })
},[])

const handleSubmit = (e) => {
    e.preventDefault();
    axios.put('labels/', labels )
        .then(
            (data) => {
               console.log(data.data);
                toast.success('Label has been edited successfully!')
                window.history.back();
            }
        )
        .catch(err => {
            toast.error('Something went wrong!')
            console.log(err)
        });
}
    console.log(labels)
    return(
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Edit Labels</h1></div>
            <form onSubmit={handleSubmit}>
              
                <input type='text' required
                       title="This field is required" placeholder='labels Name' onChange={labelsName} value={labels.name}></input>
                <button type='submit' className="buttonAction">Edit Label</button>
            </form>
        </div>

    )
}
export default EditLable;
