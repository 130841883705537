import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteCountry = (props) => {
  const [country, addcountry] = useState({});

  // const stateName = e => {
  //     console.log(e)
  //     const ud = Object.assign({}, country);
  //     ud.country_id = e.target.value
  //     addcountry(ud);
  // }

  // const countryName = e => {
  //     console.log(e)
  //     const ud = Object.assign({}, country);
  //     ud.name = e.target.value
  //     addcountry(ud);
  // }

  useEffect(() => {
    axios
      .get("countries/id?id=" + props.match.params.id)
      .then((data) => {
        addcountry(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("countries/id?id=" + props.match.params.id)
      .then((data) => {
        addcountry(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("countries/?id=" + country.id)
      .then((data) => {
        toast.success("Country has been deleted successfully!");
        window.history.back();
      })
      .catch((err) => console.log(err));
  };
  console.log(country);
  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Delete country</h1>
      </div>
      <div className="row">
        By clicking delete button you agree to delete country with name{" "}
        {country.name}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Country Name"
          value={country.name}
          disabled
        ></input>
        <input
          type="text"
          placeholder="Country Code"
          value={country.code}
          disabled
        ></input>
        <input
          type="text"
          placeholder="Phone Prefix"
          value={country.phone_code}
          disabled
        ></input>

        <button type="submit">Delete country</button>
      </form>
    </div>
  );
};
export default DeleteCountry;
