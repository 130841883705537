import React, { useEffect, useState } from "react";
import axios from "../../../axios";
// import { setSideBar } from "../../../redux/Functions/actions.js";
// import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import { PointStyle } from "../../events/pointStyle";
import { toast } from "react-toastify";
import { ModalMainButtons, ModalButtonsPosition } from "../../../styles/ModalStyle"
// import { TableStyleEvents } from "../../events/eventsStyle"
// import { MainContent } from "../../../styles/MainLayout"
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {
  Droppable,
  Draggable, DragDropContext
} from "@hello-pangea/dnd";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap
} from "react-leaflet";
import TourAddPointModal from "./addTourPointModal";
import DeleteTourModal from "./deleteTourModal";
import { MainContent } from "../../../styles/MainLayout"
import Loader from "../../Loader/Loader";
const EditTour = (props) => {
  const [center, setCenter] = useState([0, 0]);
  const [userdata, adduserdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mapState, setMapState] = useState([0, 0]);
  const [itemList, setItemList] = useState();
  const { idParams } = useParams();
  const [previousCenter, setPreviousCenter] = useState(null);
  const [located, setLocated] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [point, setPoint] = useState([]);
  const [tourData, setAllTourData] = useState([]);
  const [deleteData, setDeleteData] = useState();
  const [dnd, setDnd] = useState(false);
  const [modalType, setModalType] = useState(null);
  const getPointData = () => {
    axios.get('/point/all').then(
      data => {
        setPoint(data?.data)
      }
    ).catch(err => { console.log('Errori', err) });
  }

  const getData = () => {
    axios.get('/tour-point/' + idParams).then(
      data => {
        setItemList(data?.data);
        setIsLoading(false);
        // setMapState([Number(data.data.points[0].latitude), Number(data.data.points[0].longitude)]);
      }
    ).catch(err => { console.log('Errori', err) });
  }

  // const getAllTour = () => {
  //   axios.get('tour/all').then(
  //     data => {
  //       setAllTourData(data?.data);
  //     }
  //   ).catch(err => { console.log('Errori', err) });
  // }
  
  const getTour = () => {
    axios.get(`tour/${idParams}`).then(
      data => {
        setAllTourData(data?.data)
      }
    ).catch(err => { console.log('Errori', err) });
  }

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItemList(updatedList);
    setDnd(true);
  };
  console.log("dnd", dnd)

  const payload = {
    tourPoints: itemList
  }

  const handleEditItem = (e) => {
    e.preventDefault();

    axios
      .put(`tour-point `, payload)
      .then((data) => {
        toast.success("Tour point position changed successfully!");
        getData();
        setDnd(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Dicka shkoi gabim!");
      });

  };

  function FlyMapTo() {
    const map = useMap();
    useEffect(() => {
      if (previousCenter !== center) {
        map.flyTo(center);
        setPreviousCenter(center);
      }
    }, [center, map, previousCenter]);
    return null
  }

  useEffect(() => {
    getData();
    getPointData();
    // getAllTour();
    getTour();
  }, []);


  let map;
  const latLongFunc = async (e) => {
    const ud = Object.assign({}, userdata);
    ud.latitude = e[0];
    ud.longitude = e[1];
    await adduserdata(ud);
  };

  function MyComponent() {
    // useEffect(()=>{
    //     map.locate();
    // },[])
    map = useMapEvents({
      // whenReady: () => {
      //     console.log('loaded')
      //     locationCenter();
      // },
      click: async (e) => {
        let i = await map.mouseEventToLatLng(e.originalEvent);
        await setMapState([Number(i.lat), Number(i.lng)]);
        console.log(map);
        await latLongFunc([Number(i.lat), Number(i.lng)]);
        // await latitude(i.lat);
        //  latitude(i.lat).then(()=>longitude(i.lng));
        // setTimeout(()=>latitude(i.lat), 105);
        console.log("shop data inside click", userdata);
        // map.locate();
      },
      locationfound: async (location) => {
        if (userdata?.longitude === 0 && userdata?.latitude === 0) {
          latLongFunc([location.latlng.lat, location.latlng.lng]);
          await map.panTo([
            Number(userdata?.latitude),
            Number(userdata?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
        }
        console.log("location found:", [
          location.latlng.lat,
          location.latlng.lng,
        ]);
        if (userdata != "") {
          console.log("po hin te shopi", [
            Number(userdata?.latitude),
            Number(location?.longitude),
          ]);
          console.log("centraaaa", [
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
          await map.panTo([
            Number(userdata?.latitude),
            Number(userdata?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(userdata?.latitude),
            Number(userdata?.longitude),
          ]);
        } else {
          console.log("po hin te location default");
          await map.panTo([
            Number(location.latlng.lat),
            Number(location.latlng.lng, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(location.latlng.lat),
            Number(location.latlng.lng),
          ]);
        }
        setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
        setLocated(true);
        map.stopLocate();
      },
    });
    if (located == false) {
      map.locate();
    }
    return null;
  }
  const handleModalAdd = () => {
    setIsOpenAddModal(true);
    setModalType("add");
  };
  const handleDeleteModal = (data) => {
    setIsOpenDeleteModal(true);
    setDeleteData(data);
  };
  console.log("itemList", itemList)
  return (
    <>
      {isOpenAddModal === true && <TourAddPointModal isOpen={isOpenAddModal} handleModalClose={() => { setIsOpenAddModal(false) }} type={modalType} idParams={Number(idParams)} getData={getData} city_id={tourData.city_id}/>}
      {isOpenDeleteModal === true && <DeleteTourModal data={deleteData} isOpen={isOpenDeleteModal} handleModalClose={() => { setIsOpenDeleteModal(false) }} idParams={Number(idParams)} getData={getData} />}
      {/* <div className={props?.sidebarCollapse !== "collapsed" ? "notCollapsedSideBar text-left" : "collapsedSideBar  mt-0 text-left"}> */}

      <div className="points  text-left" style={{ padding: "3rem 2.4rem 0rem 3rem" }}>
        <div className='page-title'>
          <h1>Edit Tour Points</h1> <br />
        </div>
        {/*<button onClick={buttonState} type="btn btn-success button">Add Points</button>*/}
        <div className={"myMap"}>
          <div>
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
            {/*<button onClick={openModal} className={'map-button showme'}><i*/}
            {/*    className="fas fa-map-marker-alt"></i><span className={'hideme map-button-txt'}>Add Point Location</span>*/}
            {/*</button>*/}
            <div className="row mb-5">
              <div className="col-md-12">
                <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
                  <MyComponent />
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                   {
                itemList?.map((el)=>{
                  return(
                    <Marker position={[Number(el?.points[0]?.latitude), Number(el?.points[0]?.longitude)]}>
                  <Popup defaultOpen={true}>
                  {tourData?.name}<br/>{el.order_id} <br /> 
                  </Popup>
                </Marker>
                    )
                })
               }
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="table tableStyle table-responsive">
          <table className="custom-table-tour table table-hover" >
            <div className='row list-description mb-2'>
              {/*<div className='col-1'>{filtered.id} </div>*/}
              <div className='col-3'>Order</div>
              <div className='col-3'>	Point name</div>
              <div className='col-2'>Tour name</div>
              <div className='col-2'>Delete</div>
              {/* <div className='col-1'></div> */}
              <div className=" col-2 text-left"><ModalButtonsPosition className=''>
                <ModalMainButtons onClick={() => handleModalAdd()}><i className="fas fa-plus" /> Add Tour Point</ModalMainButtons>
              </ModalButtonsPosition></div>
            </div>

            {
              isLoading ? <Loader /> :
                <>

                  <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}   >
                          {itemList?.length > 0 ? (
                            itemList?.map((el, index) => (
                              <Draggable key={el?.id.toString()} draggableId={el?.id.toString()} index={index}>
                                {(provided) => (

                                  <div
                                    className='listimi showme'
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <div className="row">
                                      {console.log("elllll",el)}
                                      <div className='col-3'>{el?.order_id}</div>
                                      <div className='col-3'>{point?.filter((element) => element?.id == el?.point_id)[0]?.name}</div>
                                      <div className='col-2'>{tourData?.name}</div>
                                      <div className='col-1'><div onClick={() => handleDeleteModal(el)}><i className={"fa fa-trash "} /></div></div>
                                    </div>

                                  </div>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                <p className="text-muted">No Data</p>
                              </td>
                            </tr>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>


                </>
            }
          </table>
        </div>

      </div>
      <div className="mt-3">
        {point?.length > 0 &&
          <>
            {dnd ? (
              <ModalMainButtons className="ml-5 mb-4" onClick={handleEditItem}>
                Edit Tour position
              </ModalMainButtons>
            ) : (
              <p className="text-muted pl-5">Drag and drop list item to edit point position</p>
            )}
          </>
        }
      </div>


    </>
  );
};

export default (EditTour);
