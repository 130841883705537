import React, {useEffect,useState} from 'react';
import {toast} from "react-toastify";
import axios from '../../axios';

const DeleteDatatype = (props) => {

const [datatypes, adddatatypes] = useState({});



useEffect(()=>{

    axios.get('data-types/id?id=' + props.match.params.id)
         .then(    (data) => {
            adddatatypes(data.data)
        })
         .catch(err =>  console.log(err))
},[])

const handleSubmit = (e) => {
    e.preventDefault();
    axios.delete('data-types/?id='+ datatypes.id )
        .then(
            (data) => {
               toast.success('Data Type has been deleted successfully!')
               window.history.back();
            }
        )
        .catch(err => console.log(err));
}
    console.log(datatypes)
    return(
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Delete datatypes</h1></div>
            <div className='row'>By clicking delete button you agree to delete datatypes with name {datatypes.name}</div>
            <form onSubmit={handleSubmit}>
          
                <input type='text' required
                       title="This field is required" placeholder='datatypes Name'  value={datatypes.name} disabled></input>
                <input type='text' required
                       title="This field is required" placeholder='datatypes Code'  value={datatypes.type} disabled></input>
            
                <button type='submit'>Delete datatype</button>
            </form>
        </div>

    )
}
export default DeleteDatatype;
