import styled, { css } from "styled-components";


export const ModalForm = styled.form`
  width: 85%;
  max-width: 600px;
  margin: auto;

  @media only screen and (max-width: 600px) {
    /* width: 100%; */
    margin-top: 25px;
  }
`;

export const ModalHandleButtons = styled.div`
      /* background-color:#2996b6; */
    border: 0px;
    padding: 11px;
    /* position: relative; */
    color:white;
    border-radius: 50%;
    vertical-align: middle;
    display: flex;
    /* margin: 0px 1px; */
    cursor: pointer;
    color: black;
    font-size: 20px;
    border: 1px solid #BDE9F5;
  @media only screen and (max-width: 600px) {
    background-color: #2996b6;
    border: 8px;
    padding: 5px 10px;
    position: fixed;
    right: 0px;
    color: rgb(0, 0, 0);
    top: 0px;
    border-radius: 0px;
    left: unset;
    display: unset;
  }
  ${(props) =>
    props?.edit &&
    css`
/* background-color: #2996b6; */
    `}

  ${(props) =>
    props?.delete &&
    css`
      border-radius: 50%;
      background-color: #fa0d21;
    `}
  ${(props) =>
    props?.close &&
    css`
    /* background-color: #2996b6; */
    `}
  ${(props) =>
    props?.print &&
    css`
      background-color: #042D63;
    `}
  ${(props) =>
    props?.tool &&
    css`
      background-color: #042D63a9;
      border-radius: 50%;
    `}
`;



export const ModalStyle = styled.div`
  position: fixed;
  border-radius: 12px;
  background-color: white;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  height: auto;
  max-height: 800px;
  padding: 2%;
  overflow-y: auto;
height: fit-content;
  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 90%;
  }
  ${(props) =>
    props?.deletePoint &&
    css`
    bottom: 30%;
    `} 
`;

export const ModalWholePage = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  z-index: 999;
`;

export const ModalButtonsPosition = styled.div`
  display: flex;
  justify-content: end;

`;

export const ModalMainButtons = styled.button`
    padding: 7px 20px;
    border: unset;
    /* font-size: 19px; */
    font-weight: 500;
    background-color: #2996b6;
    border-radius: 12px;
    color: white;
    &:hover{
      background: #2996b6;
      /* transition: transform 500ms ease-in; */
    }
  /* display: flex;
  justify-content: end; */
  /* ${(props) =>
    props?.close &&
    css`
      background-color: #7b7b7b;
    `} */
    ${(props) =>
    props?.closeDeleteButton &&
    css`
        background-color:#e4ebf2;
        width:45%;
        color: black;
        &:hover{
          background: #F4F6F8;
          /* color:red; */
        }
      `} 
   ${(props) =>
    props?.deleteModal &&
    css`
        background-color: #FD4157;
        color: white;
        width:45%;
        &:hover{
          background: #fd4157cf;
          /* color:red; */
        }
      `} 
`;
export const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px !important;
  height: 34px;

  input {
    width: 60px;
    width: 60px;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background: #2996b6;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #0D345F;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;