import './bkp-Points.scss';
import {useEffect, useState} from "react";

import {getNodeText} from "@testing-library/dom";
import {MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents} from 'react-leaflet'


const BkpPoints = () => {


    const [shopData, setshopData] = useState([]);
    const [stateData, setstateData] = useState([]);
    const [cityData, setcityData] = useState([]);
    const [catData, setcatData] = useState([]);
    const [imageData, setimageData] = useState(null);
    const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    const [isShopEdit, setisShopEdit] = useState(false);
    const [mapState, setMapState] = useState([0, 0]);
    const [center, setCenter] = useState([0, 0]);
    const [located, setLocated] = useState(false);
    let map;

    const latLongFunc = async (e) => {
        const ud = Object.assign({}, shopData);
        ud.latitude = e[0];
        ud.longitude = e[1];
        // console.log('inside latitude',ud)
        await setshopData(ud);
    }






    return (
        <>
            <div className='points p-5 text-left'>
                <div className='page-title'>
                    <h1>Points</h1> <br />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p>Maps</p>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                {/*<MapContainer center={center} zoom={13} scrollWheelZoom={false}>*/}
                                {/*    <MyComponent/>*/}
                                {/*    <TileLayer*/}
                                {/*        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'*/}
                                {/*        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}
                                {/*    />*/}
                                {/*    {shopData != '' ?*/}
                                {/*        <Marker position={[shopData.latitude, shopData.longitude]}>*/}
                                {/*            <Popup>*/}
                                {/*                A pretty CSS3 popup. <br/> Easily customizable.*/}
                                {/*            </Popup>*/}
                                {/*        </Marker> :*/}
                                {/*        mapState != '' ?*/}
                                {/*            <Marker position={[mapState[0], mapState[1]]}>*/}
                                {/*                <Popup>*/}
                                {/*                    Pointer. <br/> Pointer.*/}
                                {/*                </Popup>*/}
                                {/*            </Marker> : ''*/}

                                {/*    }*/}
                                {/*</MapContainer>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default BkpPoints;