import styled, { css } from "styled-components";
export const PointStyle = styled.div`
    /* ${(props) =>
    props?.collapsedEvent ? css`
     margin-left: 6%;
     `
    : css`
    margin-left: 15%;
    `
    } */
`