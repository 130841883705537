import React, { useState } from "react";
import "./header.scss";
import { Menudata } from "./menudata";
import SubMenu from "./SubMenu";
import logo from '../../White.png';


const Header = () => {
  const [activeMenu, setActiveMenu] = useState();

  const handleMenu = (id) => {
    setActiveMenu(id);
  }

  return (
    <div className="header">
      <nav className="navbar navbar-expand-md navbar-dark">
        
        <a className="navbar-brand" href="#">
          <img src={logo} alt='logo' width='150px' />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            {/* {
                Menudata.map((el, index) => {
                    return <li className='nav-item' key={index}> <Link to={el.path} className='nav-link'>{el.title}</Link></li>
                })
            } */}
            {Menudata.map((item, index) => {
              return (
                <SubMenu
                  item={item}
                  key={index}
                  index={index}
                  handleMenu={handleMenu}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                />
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
