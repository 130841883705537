import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const EditPointCategories = (props) => {
  const [pointcategories, addpointcategories] = useState({});
  const [imageData, setimageData] = useState(null);

  const pointcategoriesName = (e) => {
    // console.log(e)
    const ud = Object.assign({}, pointcategories);
    ud.name = e.target.value;
    addpointcategories(ud);
  };

  const isactive = (e) => {
    // console.log(e)
    const ud = Object.assign({}, pointcategories);
    ud.active = e.target.value;
    addpointcategories(ud);
  };

  const getData = () => {
    axios
      .get("point-categories/id?id=" + props.match.params.id)
      .then((data) => {
        addpointcategories(data.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");

        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("point-categories/", pointcategories)
      .then((data) => {
        //    console.log(data.data);
        toast.success("Point Category has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  // IMAGE
  const imageDataChange = (e) => {
    const ud = Object.assign({}, imageData);
    ud.image = e.target.files[0];
    setimageData(ud);
    console.log("fajllimrena", imageData);
  };

  const saveImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageData.image);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("point-categories/image/" + props.match.params.id, formData, config)
      .then((res) => {
        console.log(res.data);
        // console.log('image',formData)
        // window.location = '/ZZ'
        // addlanguages();
        getData();
        toast.success("Image has been changed successfully!");
        // getCurrentCountry();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
    // getStore();
    // window.location.reload();
  };

  // console.log('category',pointcategories);
  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Edit Category</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row p-3">
          <img
            // src={`${process.env.REACT_APP_UPLOADS}` + countries.image_url}
            src={`${process.env.REACT_APP_UPLOADS}` + pointcategories.image_url}
            alt="Category Image"
            width="500px"
          />
        </div>
        <input
          type="file"
          // required
          title="This field is required"
          name="image"
          onChange={imageDataChange}
        ></input>
        {!!imageData && <button onClick={saveImage}>Save Image</button>}

        <input
          type="text"
          required
          title="This field is required"
          placeholder="pointcategories Name"
          onChange={pointcategoriesName}
          value={pointcategories.name}
        ></input>
        <select onChange={isactive} value={pointcategories.active}>
          <option value={true}>Active</option>
          <option value={false}>Passive</option>
        </select>

        <button type="submit" className="buttonAction">Edit Category</button>
      </form>
    </div>
  );
};
export default EditPointCategories;
