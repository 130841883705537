function isAuthenticated() {

    let Token = window.localStorage.getItem('cd');
    if (Token)
        return true;
    else
        return false;
}


export default isAuthenticated;