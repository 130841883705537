import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const EditUserRole = (props) => {

    const [role, addrole] = useState({});

    const roleName = e => {
        console.log(e)
        const ud = Object.assign({}, role);
        ud.role_name = e.target.value
        addrole(ud);
    }


    useEffect(() => {

        axios.get('user-roles/id?id=' + props.match.params.id)
            .then((data) => {
                addrole(data.data)
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put('user-roles/', role)
            .then(
                (data) => {
                    console.log(data.data);
                    window.history.back();
                    toast.success('User Role has been edited successfully!')
                }
            )
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
    }

    console.log(role)
    return (
        <div className='user_role p-5 text-left'>
            <div className='row'><h1>Edit Role</h1></div>
            <form onSubmit={handleSubmit}>
                <input type='text'
                       required
                       title="This field is required"
                       placeholder='role Name'
                       onChange={roleName}
                       value={role.role_name}>
                </input>
                <button type='submit' className="buttonAction">Edit</button>
            </form>
        </div>

    )
}
export default EditUserRole;
