import { CLEAR_DATA, LOGMEIN, SET_AUTH, SET_USER_ID} from "./types";

export const logMeIn = () => {
    return { 
        type: LOGMEIN,
    };
};

export const setAuth = (auth) => {
    return {
        type: SET_AUTH,
        auth: auth
    };
};

export const setUserID = (id, role) => {
    return {
        type: SET_USER_ID,
        user_id: id,
        user_role: role
    };
};

export const Clear = () => {
    return {
        type: CLEAR_DATA,
       
    }
}


