import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import { toast } from "react-toastify";

const AddUserRole = (props) => {

    const [role, addrole] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const roleName = e => {
        console.log(e)
        const ud = Object.assign({}, role);
        ud.role_name = e.target.value
        addrole(ud);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!submitting) {
            setSubmitting(true);
            axios.post('user-roles', role)
                .then(data => {
                    console.log(data);
                    window.history.back();
                    toast.success('User Role has been added successfully!')
                })
                .catch(err => {
                    toast.error('Something went wrong!')
                    console.log(err)
                }).finally(() => {
                    setSubmitting(false);
                })
        }

    }

    return (
        <div className='user_roles p-5 text-left'>
            <div className='row'><h1>Add role</h1></div>
            <form onSubmit={handleSubmit}>
                <input type='text' required
                    title="This field is required" placeholder='role Name' onChange={roleName}></input>
                <button type='submit' className="buttonAction" disabled={submitting}>Add Label</button>
            </form>
        </div>

    )
}
export default AddUserRole;
