import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './users.scss';
import axios from '../../axios'
import Search from "../Search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader";
import {toast} from "react-toastify";


const Users = () => {
    const [users, setusers] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };


    useEffect(() => {

        axios.get('users/all').then((res) => {
            setusers(res?.data);
            setFiltered(res?.data)
            setIsLoading(false);
        }).catch((err) => {
            toast.error('Something went wrong!')
            console.log(err)
        });
    }, [])

    return (
        <div className='users p-5 text-left'>
            <div className='page-title'>
                <h1>User Management</h1> <br/>
            </div>
            <div className='row search-add'>
            <Search data={users} filtered={setFiltered} setPageNumber={setPageNumber}/>
                <Link to='/users/add' className='myButton'>Add New User</Link>
            </div>
            <div className='row'>
            <div className='row list-description mb-2'>
                                    <div className='col-1'>Id</div>
                                    <div className='col-3'>Name</div>
                                    <div className='col-3'>Email</div>
                                    <div className='col-1'>Role</div>
                                    <div className='col-1'>Language</div>
                                    <div className='col-1'>Status</div>
                                    <div className='col-1 hideme'>Edit</div>
                                    {/* <div className='col-1 hideme'>Delete</div> */}
                                </div>
                {
                    isLoading ? <Loader/> :
                        displayUsers = filtered?.slice
                        (pagesVisited, pagesVisited + productsPerPage)?.map
                        (filtered => {
                            return (<div className='listimi showme' key={filtered.id}>
                                    <div className='row '>
                                        <div className='col-1'>{filtered.id} </div>
                                        <div className='col-3'>{filtered.full_name} </div>
                                        <div className='col-3'>{filtered.email} </div>
                                        <div className='col-1'>{filtered.role} </div>
                                        <div className='col-1'>{filtered.def_lang} </div>
                                        <div className='col-1'>{filtered.active ? 'Active' : 'Passive'} </div>
                                        <div className='col-1 hideme'>
                                            <Link className={'fa fa-edit'}
                                                  to={'/users/edit/' + filtered.id}>
                                            </Link>
                                        </div>
                                        {/* <div className='col-1 hideme'>
                                            <Link className={'fa fa-trash'}
                                                  to={'/users/delete/' + filtered.id}>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(users.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Users;