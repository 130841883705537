import React, { useEffect, useState } from "react";
import axios from '../../axios';
import ModalPortal from "../../portal/ModalPortal";
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../../styles/ModalStyle"
import { FaTimes } from "react-icons/fa";
// import { TimePicker } from "antd";
// import 'antd/dist/reset.css'
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import Modal from 'react-modal';
const EventsModal = (props) => {
    const [currentLocation, setCurrentLocation] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    const [point, setPoint] = useState([]);
    const [pointAll, setPointAll] = useState([]);
    const [cities, setCities] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [defaultImageUrl, setDefaultImageUrl] = useState(process.env.REACT_APP_UPLOADS + props?.data?.image_url);
    const [selectedImage, setSelectedImage] = useState(null);
    const formattedDefaultStartTime = props?.data?.start_time ? props?.data?.start_time.slice(0, 16) : '';
    const formattedDefaultEndTime = props?.data?.end_time ? props?.data?.end_time.slice(0, 16) : '';
    const [submitting, setSubmitting] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState();
    const [mapState, setMapState] = useState([0, 0]);
    const [center, setCenter] = useState([0, 0]);
    const [located, setLocated] = useState(false);
    const [mapData, setMapData] = useState([]);
    
    let map;
    let subtitle;

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    useEffect(()=>{
        if(props?.type === 'add'){
            setIsOpen(true);
        }else{
            setIsOpen(false)

        }
    },[props?.isOpen])
    
    useEffect(() => {
        getPointData();
        getCityData();
        getPoints();
    }, [props?.isOpen]);

    const handleEdit = (isEdit) => {
        if (isEdit) {
            setEdit(false);
            setIsAdd(false);
            setDelete(false);
        } else {
            setEdit(true);
            setIsAdd(false);
            setDelete(false);
        }
    }
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            props?.handleModalClose()
        }
    })

    const getCurrentLocation = (e) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation([latitude, longitude]);
            setMapState([0,0]);
            const ud = Object.assign({}, eventData);
            ud.latitude = latitude;
            ud.longitude = longitude;
            setEventData(ud);
          }, (error) => {
            console.error("Error getting location:", error);
          });
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      };

    
const customStyles = {
    content: {
        border: 'none',
        background: 'rgba(0,0,0,0.5);',
        padding: '0',
        borderRadius: '10px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }




    const name = (e) => {
        const ud = { ...eventData };
        ud.name = e.target.value;
        setEventData(ud);
    };
    const description = (e) => {
        const ud = { ...eventData };
        ud.description = e.target.value;
        setEventData(ud);
    };

    const start_time = (e) => {
        const ud = { ...eventData };
        const selectedDate = new Date(e.target.value);
        selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
        ud.start_time = selectedDate.toISOString();
        setEventData(ud);
    };


    const end_time = (e) => {
        const ud = { ...eventData };
        const selectedDate = new Date(e.target.value);
        selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
        ud.end_time = selectedDate.toISOString();
        setEventData(ud);
    };

    // const point_id = (e) => {
    //     const ud = { ...eventData };
    //     ud.point_id = Number(e.target.value);
    //     setEventData(ud);
    // };
    const point_id = (e) => {
        const ud = { ...eventData };
        ud.city_id = Number(e.target.value);
        setEventData(ud);
    };

    const handlePoint = (e) => {
        const ud = { ...eventData };
        ud.point_id = Number(e.target.value);
        setEventData(ud);
    };

    const external_url = (e) => {
        const ud = { ...eventData };
        ud.external_url = e.target.value;
        setEventData(ud);
    };

    const image_url = (e) => {
        const ud = { ...eventData };
        ud.image_url = e.target.files[0];
        setEventData(ud);
    };

    const eventValue = (e) => {
        const ud = { ...eventData };
        //Type 1 eshte General 2 Bussiness 3 osht Festival
        ud.type = e.target.checked ? 3 : 1;
        setEventData(ud);
    };


    useEffect(() => {
        const ud = Object.assign({}, eventData);
        ud.user_id = props?.user_id;
        ud.city_id = props?.data?.city_id;
        ud.point_id = props?.data?.point_id;
        ud.description = props?.data?.description;
        ud.external_url = props?.data?.external_url;
        // ud.image_url = props?.data?.image_url;
        ud.start_time = props?.data?.start_time;
        ud.end_time = props?.data?.end_time;
        ud.name = props?.data?.name;
        ud.latitude = props?.data?.latitude;
        ud.longitude = props?.data?.longitude;
        ud.type = props?.data?.type;
        setEventData(ud);
    }, [props?.isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!submitting) {
            if (eventData?.latitude === undefined || eventData?.longitude === undefined) {
                toast.error('Please select Point Location')
            } else {
                setSubmitting(true);
            let fd = new FormData();
            fd.append("user_id", props?.user_id);
            fd.append("city_id", eventData?.city_id);
            if (eventData?.point_id) {
                fd.append("point_id", eventData.point_id);
            }
            fd.append("description", eventData?.description);
            fd.append("external_url", eventData?.external_url);
            fd.append("image_url", eventData?.image_url);
            fd.append("start_time", eventData?.start_time);
            fd.append("end_time", eventData?.end_time);
            fd.append("name", eventData?.name);
            fd.append("latitude", eventData?.latitude);
            fd.append("longitude", eventData?.longitude);
            fd.append("type", eventData?.type ? eventData?.type : 1);
            axios
                .post(`events`, fd)
                .then((data) => {
                    props?.handleModalClose();
                    toast.success("Eventi u shtua me sukses!");
                    props?.getData();
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("Dicka shkoi gabim!");
                }).finally(() => {
                    setSubmitting(false);
                })}
        }
    };
    console.log("eventData", props?.data?.id)

    const handleEditEvent = async (e) => {
        e.preventDefault();
        let fd = new FormData();
        fd.append("id", props?.data?.id);
        fd.append("user_id", props?.user_id);
        fd.append("city_id", eventData?.city_id);
        if (eventData?.point_id) {
            fd.append("point_id", eventData.point_id);
        }
        fd.append("description", eventData?.description);
        fd.append("external_url", eventData?.external_url);
        fd.append("image_url", eventData?.image_url);
        fd.append("start_time", eventData?.start_time);
        fd.append("end_time", eventData?.end_time);
        fd.append("name", eventData?.name);
        fd.append("latitude", eventData?.latitude);
        fd.append("longitude", eventData?.longitude);
        fd.append("type", eventData?.type ? eventData?.type : 1);
        axios
            .put(`events`, fd)
            .then((data) => {
                props?.handleModalClose();
                toast.success("Eventi u ndryshua me sukses!");
                props?.getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error("Dicka shkoi gabim!");
            });
    };


    const getPointData = () => {
        axios.get('/cities/all').then(
            data => {
                setPoint(data?.data)
            }
        ).catch(err => { console.log('Errori', err) });
    }

    const getPoints = () => {
        axios.get('/point/all').then(
            data => {
                setPointAll(data?.data)
            }
        ).catch(err => { console.log('Errori', err) });
    }

    const getCityData = () => {
        axios.get('/cities/all').then(
            data => {
                setCities(data?.data)
            }
        ).catch(err => { console.log('Errori', err) });
    }

    const latLongFunc = async (e) => {
        // console.log("po hi mrena",e)
        //     const ud = Object.assign({}, eventData);
        // ud.latitude = e[0];
        // ud.longitude = e[1];
        // await setEventData(ud);

        const ud = { ...eventData };
        ud.latitude = e[0];
        ud.longitude = e[1];
        setEventData(ud);
    }


    function MyComponent() {
        map = useMapEvents({
            // whenReady: () => {
            //     console.log('loaded')
            //     locationCenter();
            // },
            click: async (e) => {
                console.log("hej",e)
                let i = await map.mouseEventToLatLng(e.originalEvent)
                await setMapState([Number(i.lat), Number(i.lng)])
                console.log(map)
                await latLongFunc([Number(i.lat), Number(i.lng)]);
                setCurrentLocation(null);

                // await latitude(i.lat);
                //  latitude(i.lat).then(()=>longitude(i.lng));
                // setTimeout(()=>latitude(i.lat), 105);
                // console.log('shop data inside click', shopData)
                // map.locate();

            },
            locationfound: async (location) => {
                console.log(location)
                console.log('location found:', [location.latlng.lat, location.latlng.lng]);
                //
                // if (mapState[0] === 0 && mapState[1] === 0) {
                //     setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
                //     setLocated(true);
                //     await map.flyTo([Number(location.latlng.lat), Number(location.latlng.lng, {
                //         animate: false
                //     })]);
                //     await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)]);
                //
                // }

                if (mapData.longitude === 0 && mapData.latitude === 0) {
                    latLongFunc([location.latlng.lat, location.latlng.lng])
                    await map.panTo([Number(mapData?.latitude), Number(mapData?.longitude, {
                        animate: false,
                    })]);
                    await setCenter([Number(mapData?.latitude), Number(mapData?.longitude)]);
                }

                if (mapData != '') {
                    // console.log('po hin te shopi', ([Number(shopData?.latitude), Number(location?.longitude)]));
                    // console.log('centraaaa', [Number(shopData?.latitude), Number(location?.longitude)]);
                    // using flyTo instead of panTo, it has some animation in it
                    await map.panTo([Number(mapData?.latitude), Number(mapData?.longitude, {
                        animate: false,
                    })]);
                    await setCenter([Number(mapData?.latitude), Number(mapData?.longitude)]);
                } else {
                    // console.log('po hin te location defauklt')
                    // using flyTo instead of panTo, it has some animation in it
                    await map.panTo([Number(location.latlng.lat), Number(location.latlng.lng, {
                        animate: false
                    })]);
                    await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)]);

                }
                // );
                // setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
                setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
                setLocated(true);
                map.stopLocate();
                // console.log(mapState)
            },
        })
        if (located == false) {
            map.locate();
        }
        return null
    }

    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });

    



    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle>
                        
                        <ModalButtonsPosition>
                            {props?.type !== "add" ? (
                                <>
                                    <ModalHandleButtons edit onClick={() => handleEdit(isEdit)} >
                                        {isEdit ? <FaTimes role="button" /> : <FaEdit role="button" />}
                                    </ModalHandleButtons>

                                    {!isEdit &&
                                        <ModalHandleButtons close onClick={() => props?.handleModalClose()} >
                                            <FaTimes role="button" />
                                        </ModalHandleButtons>}

                                </>
                            ) : (
                                <ModalHandleButtons
                                    close
                                    onClick={() => props?.handleModalClose()}
                                ><FaTimes role="button" />
                                </ModalHandleButtons>
                            )}
                        </ModalButtonsPosition>
                        <h4 className="text-center">{props?.type !== 'add' ? "Edit Event" : "Add Event"}</h4>
                        {props?.type !== 'add' ?
                                            <img className="eventImage" src={selectedImage != null ? URL.createObjectURL(selectedImage) : process.env.REACT_APP_UPLOADS + props?.data?.image_url} alt="Preview" />
                                            : ""}
                        <div className="mx-auto mt-5" style={{width:"85%"}}>

                        <button onClick={openModal} className="btn btn-success w-100 p-2" disabled={(props?.type !== 'add' && isEdit === false) ? true : false}><i
                                className="fas fa-map-marker-alt"></i><span className='ml-2'>Choose Location</span>
                            </button>
                            <button onClick={getCurrentLocation} className="btn btn-info w-100 p-2 mt-2">
                    <i className="fas fa-location-arrow"></i>
                    <span className='ms-2'>Get Current Location</span>
                </button>
                                </div>
                        <ModalForm  
                        >
                           
                        
                            <div className="mb-3">
                           
                            {eventData?.longitude !== undefined
                    ?
                        <div className="row mt-3 text-center">
                            <div className="col-md-6">
                                <h5 className='text-secondary'>Latitude: <span className='text-danger'>{eventData?.latitude}</span></h5>
                            </div>
                            <div className="col-md-6">
                                <h5 className='text-secondary'>Longitude: <span className='text-danger'>{eventData?.longitude}</span></h5>
                            </div>
                     
                        </div> : ""
                }
                            <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
           
                    <form className='formAdd'>
                        <div className="">
                            <div className="">
                            <MapContainer center={center} zoom={13} scrollWheelZoom={true}>
                            <MyComponent />
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {/* {props?.type === "add" && mapData !== '' && (
                                <Marker position={[mapData.latitude, mapData.longitude]}>
                                    <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                    </Popup>
                                </Marker>
                            )} */}
                            {props?.type !== "add" && (
                                <Marker position={[eventData?.latitude, eventData?.longitude]}>
                                    <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                    </Popup>
                                </Marker>
                            )}
                            {props?.type ==="add" && (
                                <Marker position={[mapState[0], mapState[1]]}>
                                    <Popup>
                                        Pointer. <br /> Pointer.
                                    </Popup>
                                </Marker>
                            )}
                            {currentLocation !== null && (
                                <Marker position={currentLocation}>
                                    <Popup>
                                        Current Location. <br /> Here you are.
                                    </Popup>
                                </Marker>
                            )}
                        </MapContainer>
                            </div>
                        </div>
                        <button onClick={closeModal} className="map-save-button"><i className="fas fa-save"></i>
                        </button>
                    </form>
                </Modal>
                                <span className="text-dark">Cities</span>
                                <select
                                    className="form-control"
                                    disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                    defaultValue={props?.data?.city_id}
                                    title="This field is required" onChange={point_id} required="required">
                                    <option value="" disabled selected>Select city</option>
                                    {point?.map((el) => {
                                        return <option value={el?.id} id={el?.id} selected={Number(props?.data?.city_id) == el?.id ? "selected" : false}>{el?.name}</option>
                                    })}
                                </select>

                                <span className="text-dark">Point</span>
                                <select
                                    className="form-control"
                                    disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                    defaultValue={props?.data?.point_id}
                                    title="This field is required" onChange={handlePoint} required="required">
                                    <option value="" disabled selected>Select point</option>
                                    {pointAll?.map((el) => {
                                        return <option value={el?.id} id={el?.id} selected={Number(props?.data?.point_id) == el?.id ? "selected" : false}>{el?.name}</option>
                                    })}
                                </select>
                                

                                <div className="row mt-3">
                                    <div className="col-lg-6 col-md-3 col-12 text-dark">
                                        <span className="text-dark">Start time</span>
                                        <input type="datetime-local" className="form-control" onChange={start_time}
                                            disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                            defaultValue={formattedDefaultStartTime} required />
                                    </div>
                                    <div className="col-lg-6 col-md-3 col-12 text-dark">
                                        <span className="text-dark">End time</span>
                                        <input type="datetime-local" className="form-control" onChange={end_time}
                                            disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                            defaultValue={formattedDefaultEndTime} required
                                        //  defaultValue={props?.data?.end_time} 
                                        />
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-1">
                            <div class="col-md-12 pb-2">
                                <div class="form-outline">
                                    <span class="form-label" for="firstName">Event name</span>
                                    <input type="text" class="form-control" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} onChange={name} defaultValue={props?.data?.name} required />
                                </div>
                            </div>
                            </div>
                            <div class="row mb-1">
                            <div class="col-md-12 pb-2">
                                <div class="form-outline">
                                    <span class="form-label" for="firstName">Description</span>
                                    <textarea type="text" class="form-control" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} onChange={description} defaultValue={props?.data?.description} required />
                                </div>
                            </div>
                            </div>

                            <div class="row mb-1">
                                <div class="col-md-6 mb-1 pb-2">
                                    <div class="form-outline">
                                        <span class="form-label" for="externalUrl">External url</span>
                                        <input type="text" id="externalUrl" class="form-control" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} onChange={external_url} defaultValue={props?.data?.external_url} />
                                    </div>
                                </div>

                                <div class="col-md-6 mb-1 pb-2">
                                    <div class="form-outline">
                                        <span class="form-label" for="imageUrl">Image url</span>

                                 
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="inputGroupFile"
                                            name="image"
                                            disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                            aria-describedby="inputGroupFileAddon03"
                                            aria-label="Upload"
                                            onChange={image_url}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">

                                <div className="row ">
                                    <div className="col-md-3 text-center">
                                    <input class="form-check-input"
                                         type="checkbox"
                                         value="1" 
                                         id="flexCheckDefault" 
                                         disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                         defaultChecked={props?.data?.type === 3 ? true: false} onChange={eventValue}/>
                                 
                                    </div>
                                    <div className="col-md-8">
                                    <label class="form-check-label" for="flexCheckDefault">
                                            Festival Event
                                        </label>
                                        </div>
                                         </div>
                            </div>
                            </div>
                               
                            {isEdit == false && props?.type != "add" ? "" :
                                <ModalButtonsPosition>
                                    
                                    <ModalMainButtons onClick={
                            props?.type === 'add' ? handleSubmit : handleEditEvent} disabled={submitting}>{props?.type === 'add' ? "Shto" : "Edit"}</ModalMainButtons>
                                </ModalButtonsPosition>
                            }
                        </ModalForm>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }

}
export default EventsModal