import React, { useState, useRef } from "react";
import "./header.scss";
import { Menudata } from "./menudata";
import SubMenu from "./SubMenu";
import logo from '../../Ciceron.png'

const Sidebar = () => {
  const Logout = () => {
    window.localStorage.removeItem("cd");
    window.localStorage.clear();
    window.location.reload();
  };
  const [activeMenu, setActiveMenu] = useState();
  const handleMenu = (id) => {
    setActiveMenu(id);
  };

  // console.log("menu dataaaa", Menudata);
  console.log("jfjfjf", window.location.pathname);
  const mypath = window.location.pathname;

  return (
    <div className="sidebar">
      <img src={logo}  className='logo' alt="ciceron"/>

      <ul>
        {Menudata.map((item, index) => {
          return (
            <SubMenu
              item={item}
              key={index}
              index={index}
              handleMenu={handleMenu}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
            />
          );
        })}

        <li className="logout">
          <a onClick={Logout}>Log Out</a>
        </li>
      </ul>
    </div>
  );
};
export default Sidebar;
