import axios from 'axios';

let cd = window.localStorage.getItem('cd');
const defaultInstance = axios.create({
  // baseURL: process.env.REACT_APP_API,
  // baseURL: 'http://localhost:8000/api/admin/'
  baseURL: process.env.REACT_APP_API,
  // baseURL: 'http://localhost:8000/api/admin/',
  // baseURL: 'https://srv1.harrisia.com/ciceron-api/api/admin/',

  headers: {
    Authorization: 'Bearer ' + cd,
  },
});

export default defaultInstance;