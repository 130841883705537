import React, {useState, useEffect} from 'react';
import axios from '../../axios.js'
import ReactPaginate from "react-paginate";
import PointOpeningModal from "./pointOpeningModal.js";
import DeletePointOpeningModal from "./deletePointOpeningModal.js";
import { connect } from "react-redux";
import { TableStyleEvents } from '../events/eventsStyle.js';
import {  ModalMainButtons, ModalButtonsPosition } from "../../styles/ModalStyle"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
const WorkingTime = (props) => {
    const [features, setFeatures] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);
    const [deleteId, setDeleteId] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const pagesVisited = pageNumber * productsPerPage;
    const pageCount = Math.ceil(filtered?.length / productsPerPage);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    };

    const getData = () => {
      axios.get(`/point-opening/all/${props?.pointId}`).then(
        res => {
            setFeatures(res?.data);
            setFiltered(res?.data)
            setIsLoading(false)
        }
      ).catch(err => { console.log('Errori', err) });
    }

   
    useEffect(() => {
      getData();

    }, []);
  

    const handleModalDelete = (data) => {
      setIsOpenDelete(true);
      setDeleteId(data);
    };

    const handleModalEdit = (data) => {
      setIsOpen(true);
      setModalType("edit");
      setDataToModal(data);
    };

    const handleModalAdd = () => {
      setIsOpen(true);
      setModalType("add");
    };

    return (
      <>
      {isOpen === true && <PointOpeningModal pointId={Number(props?.pointId)} getData={()=>getData()} type={modalType} cat_id={props?.category_id} data={dataToModal} isOpen={isOpen} handleModalClose={() => { setIsOpen(false); setDataToModal(null) }} user_id={props?.user_id} />}
      {isOpenDelete === true && <DeletePointOpeningModal isOpen={isOpenDelete} getData={()=>getData()} handleModalClose={() => { setIsOpenDelete(false)}} user_id={props?.user_id} eventData={deleteId} />}
        <div className='mt-5 text-left'>
        <div className='d-flex justify-content-end fisearch-add'>
        <button className='btn btn-info' onClick={handleModalAdd}>
                <i className='fa fa-plus'/> Add Day 
            </button>
            </div>
        <div className="col-md-12 mb-3 mt-4">
              <h2>Point Opening Hours</h2>
              <div className="row">
        <div className="col-md-12">
        <div className='row'>
            <div className='row list-description mb-2'>
                       
                            <div className='col'>Day</div>
                            <div className='col'>Start Time</div>
                            <div className='col'>End Time</div>
                            <div className='col'>Is Open</div>
                            <div className='col'>Edit</div>
                            <div className='col'>Delete</div>
                            </div>
                {
                    isLoading ? "Loading" :
                       filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map(filtered => {
                       
                    return (
                    <div className='listimi showme' key={filtered?.id}>
                        <div className='row '>
                            <div className='col'>{filtered?.week?.name} </div>
                            <div className='col'>{filtered?.opening_hour?.slice(11,16)} </div>
                            <div className='col'>{filtered?.ending_hour?.slice(11,16)} </div>
                            <div className='col'>{filtered?.is_open ? "Yes" : "No"} </div>
                            <div className='col'><i className='fa fa-edit ' onClick={() => handleModalEdit(filtered)}></i></div>
                            <div className='col'><i className='fa fa-trash text-danger' onClick={() => handleModalDelete(filtered)}></i></div>
                            
                        </div>
                    </div>)
                })}
            </div>
                  </div>
                  </div>
                  </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(features.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }

                </div>
            </div>
        </div>
      </>

    )

}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkingTime);