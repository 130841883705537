import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
const EditLanguage = (props) => {
  const [languages, addlanguages] = useState({});
  const [imageData, setimageData] = useState(null);
  const [isloading, setisloading] = useState(true);

  const languagesName = (e) => {
    console.log(e);
    const ud = Object.assign({}, languages);
    ud.name = e.target.value;
    addlanguages(ud);
  };

  const languagesCode = (e) => {
    console.log(e);
    const ud = Object.assign({}, languages);
    ud.code = e.target.value;
    addlanguages(ud);
  };

  const countryCode = (e) => {
    console.log(e);
    const ud = Object.assign({}, languages);
    ud.country_code = e.target.value;
    addlanguages(ud);
  };

  const isactive = (e) => {
    console.log(e);
    const ud = Object.assign({}, languages);
    ud.active = e.target.value;
    addlanguages(ud);
  };

  const getData = () => {
    axios
      .get("languages/id?id=" + props.match.params.id)
      .then((data) => {
        addlanguages(data.data);
        setisloading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("languages/", languages)
      .then((data) => {
        console.log(data.data);
        toast.success("Language has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  // IMAGE
  const imageDataChange = (e) => {
    const ud = Object.assign({}, imageData);
    ud.image = e.target.files[0];
    setimageData(ud);
    console.log("fajllimrenaV", imageData);
  };

  const saveImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageData.image);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("languages/image/" + props.match.params.id, formData, config)
      .then((res) => {
        console.log(res.data);
        // console.log('image',formData)
        // window.location = '/ZZ'
        getData();
        toast.success("Image has been changed successfully!");
        // getCurrentCountry();

        // addlanguages();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
    // getStore();
    // window.location.reload();
  };

  console.log(languages);
  return isloading ? (
    <Loader />
  ) : (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Edit languages</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row p-3">
          <img
            // src={`${process.env.REACT_APP_UPLOADS}` + countries.image_url}
            src={`${process.env.REACT_APP_UPLOADS}` + languages.image_url}
            alt="Country Image"
            width="500px"
          />
        </div>
        <input
          type="file"
          title="This field is required"
          name="image"
          onChange={imageDataChange}
        ></input>
        {!!imageData && <button onClick={saveImage}>Save Image</button>}
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Languages Name"
          onChange={languagesName}
          value={languages.name}
        ></input>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Languages Code"
          onChange={languagesCode}
          value={languages.code}
        ></input>
        <input
            type="text"
            required
            title="This field is required"
            placeholder="Country Code"
            onChange={countryCode}
            value={languages.country_code}
        ></input>
        <select onChange={isactive} value={languages.active}>
          <option value={true}>Active</option>
          <option value={false}>Passive</option>
        </select>

        <button type="submit" className="buttonAction">Edit languages</button>
      </form>
    </div>
  );
};
export default EditLanguage;
