import React, { useEffect, useState } from "react";
import axios from '../../axios';
import ModalPortal from "../../portal/ModalPortal";
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../../styles/ModalStyle"
import { FaTimes } from "react-icons/fa";

import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";

const FeaturesModal = (props) => {
    const [isEdit, setEdit] = useState(false);
    const [isdelete, setDelete] = useState(false);
    // const [isAdd, setIsAdd] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [modalIsOpen, setIsOpen] = useState();
    const [categories, setCategories] = useState();

    const [addList, setAddList] = useState({
        user:props?.userData?.id,
    })

    const getCategories = () => {
        axios.get(`point-categories/all`).then(
        res => {
            setCategories(res?.data);

        }
      ).catch(err => { console.log('Errori', err) });
    }

    useEffect(()=>{
        getCategories();
    },[])

    useEffect(()=>{
        if(props?.type === 'add'){
            setIsOpen(true);
        }else{
            setIsOpen(false)
        }
    },[props?.isOpen])


    const handleEdit = (isEdit) => {
        if (isEdit) {
            setEdit(false);
            // setIsAdd(false);
            setDelete(false);
        } else {
            setEdit(true);
            // setIsAdd(false);
            setDelete(false);
        }
    }
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            props?.handleModalClose()
        }
    })


    const handleChange = (e) => {
        setAddList({
            ...addList,
            [e?.target?.name]: e?.target?.value,
        })
  }

  console.log("addList",addList)

  const handleList = (e) => {
    e.preventDefault();
    axios.post('/listing-features', addList)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e listes janë shtuar me sukses!');
      }
      ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
      });
  }

  const handleEditList = (e) => {
    e.preventDefault();
    axios.put(`/listing-features/${props?.data?.id}`, addList)
      .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e listes janë edituar me sukses!');
      }
      ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
      });
  }




    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle>
                        
                        <ModalButtonsPosition>
                            {props?.type !== "add" ? (
                                <>
                                    <ModalHandleButtons edit onClick={() => handleEdit(isEdit)} >
                                        {isEdit ? <FaTimes role="button" /> : <FaEdit role="button" />}
                                    </ModalHandleButtons>

                                    {!isEdit &&
                                        <ModalHandleButtons close onClick={() => props?.handleModalClose()} >
                                            <FaTimes role="button" />
                                        </ModalHandleButtons>}

                                </>
                            ) : (
                                <ModalHandleButtons
                                    close
                                    onClick={() => props?.handleModalClose()}
                                ><FaTimes role="button" />
                                </ModalHandleButtons>
                            )}
                        </ModalButtonsPosition>
                        <h4 className="text-center">{props?.type !== 'add' ? "Edit Feature" : "Add Feature"}</h4>
               
                        <ModalForm  
                        >
                           
                        
                            <div className="mb-3">
                            </div>
                            <div class="row mb-1">
                            <div class="col-md-12 pb-2">
                                <div class="form-outline">
                                    <span class="form-label" for="firstName">Name</span>
                                    <input type="text" class="form-control" name="name" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} onChange={handleChange} defaultValue={props?.data?.name} required />
                                </div>
                            </div>
                            </div>
                            <span class="form-label">Category</span>
                            <select className="form-control"
                                onChange={handleChange}
                               name="category_id"
                               disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                placeholder="test"
                                     >
                                  <option value="" selected disabled>Select Category</option>
                                  {categories?.map((el) => {
                                        return <option key={el.id} value={Number(el?.id)}  id={el?.id} selected={Number(props?.data?.category_id) === el?.id ? "selected" : false}>{el?.name}</option>
                                    })}
                                </select>
                            <div class="row mb-1">
                            <div class="col-md-12 pb-2">
                                <div class="form-outline">
                                    <span class="form-label" for="firstName">Description</span>
                                    <textarea type="text" class="form-control" name="description" rows="2" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} onChange={handleChange} defaultValue={props?.data?.description} required />
                                </div>
                            </div>
                            </div>
                            
                            {isEdit == false && props?.type != "add" ? "" :
                                <ModalButtonsPosition>
                                    
                                    <ModalMainButtons onClick={
                            props?.type === 'add' ? handleList : handleEditList} disabled={submitting}>{props?.type === 'add' ? "Shto" : "Edit"}</ModalMainButtons>
                                </ModalButtonsPosition>
                            }
                        </ModalForm>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }

}
export default FeaturesModal