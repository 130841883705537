import React, {useEffect,useState} from 'react';
import { toast } from "react-toastify";
import axios from '../../axios';

const DeleteUserRole = (props) => {

const [role, addrole] = useState({});

useEffect(()=>{

    axios.get('user-roles/id?id=' + props.match.params.id)
         .then(    (data) => {
            addrole(data.data)
        })
         .catch(err =>  console.log(err))
},[])

const handleSubmit = (e) => {
    e.preventDefault();
    axios.delete('user-roles/?id='+ role.id )
        .then(
            (data) => {
                toast.success("User Role has been deleted successfully!");
               window.history.back();
            }
        )
        .catch(err => console.log(err));
}
    console.log(role)
    return(
        <div className='user_role p-5 text-left'>
            <div className='row'><h1>Delete Role</h1></div>
            <div className='row'>By clicking delete button you agree to delete user role with name {role.name}</div>
            <form onSubmit={handleSubmit}>
          
                <input type='text' placeholder='role Name'  value={role.role_name} disabled></input>            
                <button type='submit'>Delete</button>
            </form>
        </div>

    )
}
export default DeleteUserRole;
