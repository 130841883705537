import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const AddCountry = (props) => {
  const [country, addcountry] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const countryName = (e) => {
    console.log(e);
    const ud = Object.assign({}, country);
    ud.name = e.target.value;
    addcountry(ud);
  };

  const countryCode = (e) => {
    console.log(e);
    const ud = Object.assign({}, country);
    ud.code = e.target.value;
    addcountry(ud);
  };

  const phoneCode = (e) => {
    console.log(e);
    const ud = Object.assign({}, country);
    ud.phone_code = e.target.value;
    addcountry(ud);
  };

  // Image
  const imageDataChange = (e) => {
    const ud = Object.assign({}, country);
    ud.image_url = e.target.files[0];
    addcountry(ud);
    // console.log("fajllimrenaV", selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!submitting) {
      setSubmitting(true);

    const fd = new FormData();
    fd.append("image", country.image_url);
    fd.append("name", country.name);
    fd.append("code", country.code);
    fd.append("phone_code", country.phone_code);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };
    await axios
      .post("countries", fd)
      .then((data) => {
        console.log(data);
        toast.success("Country has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      }).finally(() => {
        setSubmitting(false);
      })}
  };

  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Add country</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={imageDataChange} />
        <input
          type="text"
          placeholder="Country Name"
          onChange={countryName}
          required
          title="This field is required"
        ></input>
        <input
          type="text"
          placeholder="Country Code"
          onChange={countryCode}
          required
          title="This field is required"
        ></input>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Phone Prefix"
          onChange={phoneCode}
        ></input>

        <button type="submit" className="buttonAction" disabled={submitting}>Add country</button>
      </form>
    </div>
  );
};
export default AddCountry;
