import React, {useState, useEffect} from 'react';
import './events.scss';
import axios from '../../axios.js'
import Search from "../Search/Search.js";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader.js";
import FeaturesModal from "./featuresModal.js";
import DeleteFeatureModal from "./deleteFeatureModal.js";
import { connect } from "react-redux";
const ListingFeatures = (props) => {

    const [features, setFeatures] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [dataToModal, setDataToModal] = useState(null);
    const [deleteId, setDeleteId] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [categories, setCategories] = useState();
    const productsPerPage = 8;
    const pagesVisited = pageNumber * productsPerPage;
    const pageCount = Math.ceil(filtered?.length / productsPerPage);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    };

    const getCategories = () => {
      axios.get(`point-categories/all`).then(
      res => {
          setCategories(res?.data);

      }
    ).catch(err => { console.log('Errori', err) });
  }



    const getData = () => {
      axios.get('listing-features/all').then(
        res => {
            setFeatures(res?.data);
            setFiltered(res?.data)
            setIsLoading(false)
        }
      ).catch(err => { console.log('Errori', err) });
    }

   
    useEffect(() => {
      getData();
      getCategories();
    }, []);
  
    const handleModalEdit = (data) => {
      setIsOpen(true);
      setModalType("edit");
      setDataToModal(data);
    };

    const handleModalDelete = (data) => {
      setIsOpenDelete(true);
      setDeleteId(data);
    };

    const handleModalAdd = () => {
      setIsOpen(true);
      setModalType("add");
    };

    const getCategoryName = (categoryId) => {
      const category = categories?.find(cat => cat.id === categoryId);
      return category ? category?.name : 'Unknown Category';
    };
  

    return (
      <>
      {isOpen === true && <FeaturesModal getData={()=>getData()} type={modalType} data={dataToModal} isOpen={isOpen} handleModalClose={() => { setIsOpen(false); setDataToModal(null) }} user_id={props?.user_id} />}
      {isOpenDelete === true && <DeleteFeatureModal isOpen={isOpenDelete} getData={()=>getData()} handleModalClose={() => { setIsOpenDelete(false)}} user_id={props?.user_id} eventData={deleteId} />}
        <div className='points p-5 text-left'>
            <div className='page-title'>
                <h1>Listing Features</h1> <br/>
            </div>
            <div className='row search-add'>
            <Search data={features} filtered={setFiltered} setPageNumber={setPageNumber}/>
            <button className='btn btn-info' onClick={() => handleModalAdd()}>
                Add new 
            </button>
            </div>
            <div className='row'>
            <div className='row list-description mb-2'>
                       
                            <div className='col'>Feature Name</div>
                            <div className='col'>Description</div>
                            <div className='col'>Category</div>
                            <div className='col'>Edit</div>
                            <div className='col'>Delete</div>
                            </div>
                {
                    isLoading ? <Loader/> :
                       filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map(filtered => {
                       
                    return (
                    <div className='listimi showme' key={filtered?.id}>
                        <div className='row '>
                            <div className='col'>{filtered?.name} </div>
                            <div className='col'>{filtered?.description?.length > 30 ? filtered?.description?.slice(0, 30) + '...' : filtered?.description} </div>
                            <div className='col'>{getCategoryName(filtered?.category_id)}</div>
                            <div className='col'><i className='fa fa-edit' onClick={() => handleModalEdit(filtered)}></i></div>
                            <div className='col'><i className='fa fa-trash text-danger' onClick={() => handleModalDelete(filtered)}></i></div>
                            
                        </div>
                    </div>)
                })}
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(features.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }

                </div>
            </div>
        </div>
      </>

    )

}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingFeatures);