import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const EditCountry = (props) => {
  const [country, addcountry] = useState({});
  const [imageData, setimageData] = useState(null);

  const countryName = (e) => {
    console.log(e);
    const ud = Object.assign({}, country);
    ud.name = e.target.value;
    addcountry(ud);
  };

  const countryCode = (e) => {
    console.log(e);
    const ud = Object.assign({}, country);
    ud.code = e.target.value;
    addcountry(ud);
  };

  const phoneCode = (e) => {
    console.log(e);
    const ud = Object.assign({}, country);
    ud.phone_code = e.target.value;
    addcountry(ud);
  };

  const getCurrentCountry = () => {
    axios
      .get("countries/id?id=" + props.match.params.id)
      .then((data) => {
        addcountry(data.data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  useEffect(() => {
    getCurrentCountry();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("countries/", country)
      .then((data) => {
        console.log(data.data);
        toast.success("Country has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  const imageDataChange = (e) => {
    const ud = Object.assign({}, imageData);
    ud.image = e.target.files[0];
    setimageData(ud);
    console.log("fajllimrena", imageData);
  };

  const saveImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageData.image);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("countries/image/" + props.match.params.id, formData, config)
      .then((res) => {
        console.log(res.data);
        // console.log('image',formData)
        // window.location = '/ZZ'
        setimageData(null);
        toast.success("Image has been changed successfully!");
        getCurrentCountry();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
    // getStore();
    // window.location.reload();
  };

  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Edit country</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row p-3">
          <img
            src={`${process.env.REACT_APP_UPLOADS + country.image_url}`}
            alt="Country Image"
            width="500px"
          />
        </div>
        <input
          type="file"
          // required
          title="This field is required"
          name="image"
          onChange={imageDataChange}
        ></input>
        {!!imageData && <button onClick={saveImage} className="btn-success w-100">Save Image</button>}
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Country Name"
          onChange={countryName}
          value={country.name}
        ></input>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Country Code"
          onChange={countryCode}
          value={country.code}
        ></input>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Phone Prefix"
          onChange={phoneCode}
          value={country.phone_code}
        ></input>

        <button type="submit" className="buttonAction">Edit country</button>
      </form>
    </div>
  );
};
export default EditCountry;
