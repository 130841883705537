import React, {useEffect,useState} from 'react';
import { toast } from "react-toastify";
import axios from '../../axios';

const DeletePointSubCategory = (props) => {

const [subcategories, addsubcategories] = useState({});
const [categories, addcategories] = useState({});
// const [labels, addlabels] = useState({});
const [isloading, setisloading] = useState(false);


useEffect(()=>{
    axios.get('point-subcategories/id?id='+ props.match.params.id).then((data) => {addsubcategories(data.data);}).catch(err => console.log(err));
    axios.get('point-categories/all').then(data => {addcategories(data.data); setisloading(true)}).catch(err =>  console.log(err));
    // axios.get('labels/all').then((data) => {addlabels(data.data); setisloading(true)}).catch(err =>  console.log(err));
},[])






const handleSubmit = (e) => {
    e.preventDefault();
    axios.delete('point-subcategories/?id='+ subcategories.id )
        .then(data => {
            toast.success("Sub Category has been deleted successfully!");
        window.history.back();
        })
        .catch(err => console.log(err));

}
// console.log(categories);
// console.log(labels);

    return(
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Delete Sub Category</h1></div>
            <div className='row mb-3'>By clicking delete button you agree to delete sub category with the following data:</div>
            {!isloading ? '' :
            <form onSubmit={handleSubmit}>
                <select  defaultValue={subcategories.category_id} disabled>
                    {/* <option disabled selected>Select</option> */}
                    {categories?.map((el,index)=> {
                        return (<option value={el.id} key={index}>{el.name}</option>)
                    })}

                </select>
                <input type='text' placeholder='Name'  defaultValue={subcategories.name} disabled />
                <select  value={subcategories.active} disabled>
                    <option>Select</option>
                 <option value={true}>Active</option>
                 <option value={false}>Passive</option>

                </select>
                <button type='submit'>Delete</button>
            </form>
            }
        </div>

    )
}
export default DeletePointSubCategory;
