import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const AddUser = (props) => {

    const [userdata, adduserdata] = useState({});
    const [userroles, adduserroles] = useState([]);
    const [languages, addlanguages] = useState([]);
    const [city, setCity] = useState([]);
    const [isloading, setisloading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        defValues();
        axios.get('user-roles/all').then(data => adduserroles(data.data))
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
        axios.get('languages/all').then((data) => {
            addlanguages(data.data);
            setisloading(true)
        }).catch(err => {
            toast.error('Something went wrong!')
            console.log(err)
        });
        axios.get('cities/all').then((data) => {
            setCity(data?.data);
        }).catch(err => {
            toast.error('Something went wrong!')
            console.log(err)
        });
    }, [])

    const defValues = () => {
        const ud = Object.assign({}, userdata);
        ud.auth = 'initial-auth';
        ud.mobileauth = 'initial-auth';
        adduserdata(ud);
    }

    const full_name = e => {
        const ud = Object.assign({}, userdata);
        ud.full_name = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const email = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.email = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const pass = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.pass = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const phone = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.phone = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const def_lang = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.def_lang = Number(e.target.value)
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const role = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.role = Number(e.target.value)
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const active = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.active = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const city_id = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.city_id = Number(e.target.value)
        adduserdata(ud);
        // console.log('seeme',userdata)
    }

    console.log("userdata",userdata)
    const handleSubmit = (e) => {
        e.preventDefault();
        if(!submitting) {
            setSubmitting(true);
        axios.post('users', userdata)
            .then(data => {
                console.log(data);
                toast.success('User has been added successfully!')
                window.history.back();

            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            }).finally(() => {
                setSubmitting(false);
              })}

    }

    console.log(userroles);
    console.log(languages);

    return (
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Add User</h1></div>
            {!isloading ? '' :
                <form onSubmit={handleSubmit}>
                    <label htmlFor="">Full Name</label>
                    <input type='text'
                           placeholder='full name'
                           onChange={full_name}
                           required
                           title="This field is required"
                    ></input>
                    <label htmlFor="">Email</label>
                    <input type='email' required
                           title="This field is required" placeholder='email' onChange={email}></input>
                           <label htmlFor="">Password</label>
                    <input type='password' required
                           title="This field is required" placeholder='your password' onChange={pass}></input>
                           <label htmlFor="">Phone</label>
                    <input type='text' required 
                           title="This field is required" placeholder='phone' onChange={phone}></input>
                              <label htmlFor="">City</label>
                    <select required
                            title="This field is required" onChange={city_id}>
                        <option value="" selected>Select</option>
                        {city?.map((el) => {
                            return (<option value={el?.id}>{el?.name}</option>)
                        })}

                    </select>
                           <label htmlFor="">Language</label>
                    <select required
                            title="This field is required" onChange={def_lang}>
                        <option value="" selected>Select</option>
                        {languages?.map((el) => {
                            return (<option value={el?.id}>{el?.name}</option>)
                        })}

                    </select>
                    <label htmlFor="">Role</label>
                    <select required
                            title="This field is required" onChange={role}>
                        <option value="" selected>Select</option>
                        {userroles?.map((el) => {
                            return <option value={el?.id}>{el?.role_name}</option>
                        })}

                    </select>
                    <label htmlFor="">Status</label>
                    <select required
                            title="This field is required" onChange={active}>
                        <option value="">Select</option>
                        <option value={0}>Passive</option>
                        <option value={1}>Active</option>
                    </select>
                 
                    <button type='submit' className="buttonAction" disabled={submitting}>Add User</button>
                </form>
            }
        </div>

    )
}
export default AddUser;
