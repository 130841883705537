import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const AddPointCategories = (props) => {
  const [pointcategories, addpointcategories] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const pointcategoriesName = (e) => {
    console.log(e);
    const ud = Object.assign({}, pointcategories);
    ud.name = e.target.value;
    addpointcategories(ud);
  };

  const isactive = (e) => {
    console.log(e);
    const ud = Object.assign({}, pointcategories);
    ud.active = e.target.value;
    addpointcategories(ud);
  };

  // Image
  const imageDataChange = (e) => {
    const ud = Object.assign({}, pointcategories);
    ud.image_url = e.target.files[0];
    addpointcategories(ud);
    // console.log("fajllimrenaV", selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!submitting) {
      setSubmitting(true);
    const fd = new FormData();
    fd.append("image", pointcategories.image_url);
    fd.append("name", pointcategories.name);
    fd.append("active", pointcategories.active);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };
    await axios
      .post("point-categories", fd)
      .then((data) => {
        toast.success("Point Category has been added successfully!");
        console.log(data);
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      }).finally(() => {
        setSubmitting(false);
      })}
  };

  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Add Point Category</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={imageDataChange} />
        <input
          type="text"
          required
          title="This field is required"
          placeholder="Category Name"
          onChange={pointcategoriesName}
        ></input>

        <select required title="This field is required" onChange={isactive}>
          <option value="" disabled selected>
            Select
          </option>
          <option value={0}>Passive</option>
          <option value={1}>Active</option>
        </select>
        <button type="submit" className="buttonAction" disabled={submitting}>Add Category</button>
      </form>
    </div>
  );
};
export default AddPointCategories;
