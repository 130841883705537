import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import { connect } from "react-redux";
import {
    setAuth,
    setUserID,
  } from "../../redux/Functions/actions";


import './points.scss'

import Modal from 'react-modal';
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from "react-leaflet";
import {Link} from "react-router-dom";
import EditModal from "./editModal";
import {toast} from "react-toastify";

const customStyles = {
    content: {
        border: 'none',
        background: 'rgba(0,0,0,0.5);',
        padding: '0',
        borderRadius: '10px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const customStyles2 = {
    content: {
        // display: 'flex',
        // justifyContent: 'center',
        // alignContent: 'center',
        // // width: '20%',
        // // height: '85%',
        // border: '3px solid red',
        // background: 'rgba(12,12,0,0.5);',
        // padding: '0',
        // marginTop: '3rem',
        // borderRadius: '10px',
        // position: 'relative',
        // top: '50%',
        // left: '90%',
        // right: 'auto',
        // bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)',
    },
};
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');


const AddUser = (props) => {
    const [currentLocation, setCurrentLocation] = useState(null);
    const [userdata, adduserdata] = useState({});
    const [userroles, adduserroles] = useState([]);
    const [languages, addlanguages] = useState([]);
    const [isloading, setisloading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [mapValidation, setMapValidation] = useState('');
    const [mapData, setMapData] = useState([]);
    const [stateData, setstateData] = useState([]);
    const [cityData, setcityData] = useState([]);
    const [catData, setcatData] = useState([]);
    // const [imageData, setimageData] = useState(null);
    const [ndryshoImazhin, setndryshoImazhin] = useState(false);
    const [isShopEdit, setisShopEdit] = useState(false);
    const [mapState, setMapState] = useState([0, 0]);
    const [center, setCenter] = useState([0, 0]);
    const [located, setLocated] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [countries, setCountries] = useState([]);
    const [subCategories, setSubCategories] = useState([])
    const [categories, setCategories] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(true);
    const [submitting, setSubmitting] = useState(false);
    console.log("props",props)

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }


    const [cities, setCities] = useState([]);


    let map;
    let subtitle;

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    const latLongFunc = async (e) => {
        const ud = Object.assign({}, userdata);
        ud.latitude = e[0];
        ud.longitude = e[1];
        await adduserdata(ud);
    }

    // console.log('map data yyyyyyyyyyy', mapData)

    const latitude = (e) => {
        const ud = Object.assign({}, mapData);
        ud.latitude = e.target.value;
        setMapData(ud);
    };
    const longitude = (e) => {
        const ud = Object.assign({}, mapData);
        ud.longitude = e.target.value;
        setMapData(ud);
    };


    // console.log('leaflet map')


    function MyComponent() {
        // useEffect(()=>{
        //     map.locate();
        // },[])
        map = useMapEvents({
            // whenReady: () => {
            //     console.log('loaded')
            //     locationCenter();
            // },
            click: async (e) => {
                let i = await map.mouseEventToLatLng(e.originalEvent)
                await setMapState([Number(i.lat), Number(i.lng)])
                console.log(map)
                await latLongFunc([Number(i.lat), Number(i.lng)]);
                setCurrentLocation(null);

                // await latitude(i.lat);
                //  latitude(i.lat).then(()=>longitude(i.lng));
                // setTimeout(()=>latitude(i.lat), 105);
                // console.log('shop data inside click', shopData)
                // map.locate();

            },
            locationfound: async (location) => {
                console.log(location)
                console.log('location found:', [location.latlng.lat, location.latlng.lng]);
                //
                // if (mapState[0] === 0 && mapState[1] === 0) {
                //     setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
                //     setLocated(true);
                //     await map.flyTo([Number(location.latlng.lat), Number(location.latlng.lng, {
                //         animate: false
                //     })]);
                //     await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)]);
                //
                // }

                if (mapData.longitude === 0 && mapData.latitude === 0) {
                    latLongFunc([location.latlng.lat, location.latlng.lng])
                    await map.panTo([Number(mapData?.latitude), Number(mapData?.longitude, {
                        animate: false,
                    })]);
                    await setCenter([Number(mapData?.latitude), Number(mapData?.longitude)]);
                }

                if (mapData != '') {
                    // console.log('po hin te shopi', ([Number(shopData?.latitude), Number(location?.longitude)]));
                    // console.log('centraaaa', [Number(shopData?.latitude), Number(location?.longitude)]);
                    // using flyTo instead of panTo, it has some animation in it
                    await map.panTo([Number(mapData?.latitude), Number(mapData?.longitude, {
                        animate: false,
                    })]);
                    await setCenter([Number(mapData?.latitude), Number(mapData?.longitude)]);
                } else {
                    // console.log('po hin te location defauklt')
                    // using flyTo instead of panTo, it has some animation in it
                    await map.panTo([Number(location.latlng.lat), Number(location.latlng.lng, {
                        animate: false
                    })]);
                    await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)]);

                }
                // );
                // setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
                setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
                setLocated(true);
                map.stopLocate();
                // console.log(mapState)
            },
        })
        if (located == false) {
            map.locate();
        }
        return null
    }
    const getCategories = async () => {
        try {
            let response = await axios.get('point-categories/all');
            setCategories(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getCities = async () => {
        try {
            let response = await axios.get('cities/all');
            setCities(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getAllCountries = async () => {
        try {
            let response = await axios.get('countries/all');
            setCountries(response?.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getSubCategories = async () => {
        try {
            let response = await axios.get('point-subcategories/all');
            setSubCategories(response.data);
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        // getImageData();
        // getDescriptionData();
        // getAudioData();
        getCities();
        getCategories();
        getSubCategories();
        defValues();
        getAllCountries();
        axios.get('languages/all').then((data) => {
            addlanguages(data.data);
            setisloading(true)
        }).catch(err => {
            toast.error('Something went wrong!')
            console.log(err)
        });
    }, [])
    
    useEffect(() => {
        const ud = Object.assign({}, userdata);
        ud.country_id = 2;
        adduserdata(ud);
    }, [])

    const defValues = () => {
        const ud = Object.assign({}, userdata);
        ud.auth = 'initial-auth';
        ud.mobileauth = 'initial-auth';
        adduserdata(ud);
    }

    const pointName = e => {
        const ud = Object.assign({}, userdata);
        ud.name = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }

    const def_lang = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.def_lang = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }

    const country = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.country_id = e.target.value
        console.log('ZZZZZZZZZZZZZZZZZZ', ud.country)
        adduserdata(ud);
        // console.log('seeme',userdata)
    }

    const city = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.city_id = e.target.value

        adduserdata(ud);
        // console.log('seeme',userdata)
    }

    const category = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.category_id = e.target.value
        console.log(ud)
        adduserdata(ud);
        // console.log('seeme',userdata)
    }

    const subCategory = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.subcategory_id = e.target.value
        console.log(ud)
        adduserdata(ud);
        // console.log('seeme',userdata)
    }


    const active = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.active = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }

    const visibility = (e) => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.is_visible = e.target.value;
        adduserdata(ud);
        // console.log('seeme',userdata)
      };

    const buttonState = () => {
        setDisableButton(!disableButton);
    }

    console.log('heheh', disableButton);


    const handleSubmit = (e) => {
        e.preventDefault();

   
        if(userdata?.latitude === undefined || userdata?.longitude === undefined){
            toast.error('Please select Point Location')
        }else if(!submitting){
            setSubmitting(true);
        axios.post('point', {
            user_id: props?.user_id,
            ...userdata
        })
            .then((res) => {
                toast.success('Point has been added successfully!');
                console.log(res);
                window.history.back();
            })
            .catch((error) => {
                toast.error('Something went wrong!')
                console.log(error)
            }).finally(() => {
                setSubmitting(false);
              })}
        }


        const getCurrentLocation = (e) => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setCurrentLocation([latitude, longitude]);
                setMapState([0,0]);
                const ud = Object.assign({}, userdata);
                ud.latitude = latitude;
                ud.longitude = longitude;
                adduserdata(ud);
              }, (error) => {
                console.error("Error getting location:", error);
              });
            } else {
              console.error("Geolocation is not supported by this browser.");
            }
          };
    
    // console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz' , Object.keys(userdata).length );
    // console.log(languages);
    //
    // let objectLength = Object.keys(userdata).length;


    const submitCoordinates = async (event) => {
        event.preventDefault();

        try {
            let response = await axios.put('point/location', {
                id: 4,
                ...mapData
            })
            toast.success('Coordinates has been added successfully!')
            setMapValidation('Saved');
            console.log(response)
            setTimeout(() => {
                setMapValidation('');
            }, 3000)
        } catch (error) {
            toast.error('Something went wrong!')
            setValidationError('Failed')
            console.log(error)
        }
    }


    return (
        <div className='points p-5 text-left'>
            <div className='row'><h1>Add Point</h1></div>
            {/*<button onClick={buttonState} type="btn btn-success button">Add Points</button>*/}
            <div className={'myMap'}>
                <div>
                </div>
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
                <div className="row">
                <div className="col-md-12">

                <button onClick={openModal} className="btn btn-info w-50 p-2 mt-2"><i
                    className="fas fa-map-marker-alt"></i><span
                    className={'map-button-txt'}>Add Point Location</span>
                </button>
                    </div>
                    <div className="col-md-12">

                <button onClick={getCurrentLocation} className="btn btn-success w-50 p-2 mt-2">
                    <i className="fas fa-location-arrow"></i>
                    <span className='ms-2'>Get Current Location</span>
                </button>
                    </div>
                    </div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <form onSubmit={submitCoordinates}>
                        <div className="row mb-5 ">
                            <div className="col-md-12">
                                <MapContainer center={center} zoom={13} scrollWheelZoom={true}>
                                    <MyComponent/>
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {mapData != '' && (
                                    <Marker position={[mapData.latitude, mapData.longitude]}>
                                        <Popup>
                                        A pretty CSS3 popup. <br /> Easily customizable.
                                        </Popup>
                                    </Marker>
                                    )}
                                    {mapState != '' && mapState.length === 2 && (
                                    <Marker position={[mapState[0], mapState[1]]}>
                                        <Popup>
                                        Pointer. <br /> Pointer.
                                        </Popup>
                                    </Marker>
                                    )}
                                    {currentLocation != null && (
                                    <Marker position={currentLocation}>
                                        <Popup>
                                        Current Location. <br /> Here you are.
                                        </Popup>
                                    </Marker>
                                    )}
                                </MapContainer>
                            </div>
                        </div>
                        <button onClick={closeModal} className="map-save-button"><i className="fas fa-save"></i>
                        </button>
                    </form>
                </Modal>
            </div>

            {!isloading ? '' :
                <form onSubmit={handleSubmit}>
                    <div className={'row p-3'}>{'Lat: ' + userdata.latitude + '  Long: ' + userdata.longitude}</div>
                    <span className="p-2 text-muted">Name</span>
                    <input type='text' required className="form-control"
                           title="This field is required"  placeholder='Point name' onChange={pointName}></input>
                    <span className="p-2 text-muted">Select Category</span>
                    <select required className="form-control"
                            title="This field is required" onChange={category}>
                        <option value="" disabled selected>Select Category</option>
                        {categories?.map((el) => {
                            return <option value={Number(el?.id)}>{el?.name}</option>
                        })}
                    </select>
                    <span className="p-2 text-muted">Select Sub-Category</span>
                    <select required className="form-control"
                            title="This field is required" onChange={subCategory}>
                        <option value="" disabled selected>Select Sub-Category</option>
                        {subCategories?.map((el) => {
                            return <option value={Number(el?.id)}>{el?.name}</option>
                        })}
                    </select>
                    <span className="p-2 text-muted">Select City</span>
                    <select required className="form-control"
                            title="This field is required" onChange={city}>
                        <option value="" disabled selected>Select City</option>
                        {cities?.map((el) => {
                            return <option value={Number(el?.id)}>{el?.name}</option>
                        })}
                    </select>
                    <span className="p-2 text-muted">Select Country</span>
                    <select required className="form-control"
                            title="This field is required" onChange={country}>
                        <option value="" disabled selected>Select Country</option>
                        {countries?.map((el) => {
                            return <option value={Number(el?.id)} selected={Number(el?.id)==1 ? "selected":false}>{el?.name}</option>
                        })}
                    </select>
                    <span className="p-2 text-muted">Select Language</span>
                    <select required className="form-control"
                            title="This field is required" onChange={def_lang}>
                        <option value="" disabled selected>Select Language</option>
                        {languages?.map((el) => {
                            return (<option value={Number(el?.id)}>{el?.name}</option>)
                        })}
                    </select>
                    <span className="p-2 text-muted">Select Status</span>
                    <select required className="form-control"
                            title="This field is required" onChange={active}>
                        <option value="" disabled selected>Select Status</option>
                        <option value={0}>Passive</option>
                        <option value={1}>Active</option>
                    </select>
                    <span className="p-2 mb-3 text-muted">Select Visibility</span>
                  <select className="form-control mb-3" onChange={visibility}>
                    <option>Select Visibility</option>
                    <option value={0}>Passive</option>
                    <option value={1}>Active</option>
                  </select>

                    <button type='submit' className='w-100 edit-point-button' disabled={submitting}>Add Point</button>
                </form>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.data.auth,
      user_id: state.data.user_id,
      user_role: state.data.user_role,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setUserID: (id, role) => dispatch(setUserID(id, role)),
      setAuth: (auth) => dispatch(setAuth(auth)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
// export default AddUser;
