import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import {
    ModalStyle, 
    ModalMainButtons, ModalButtonsPosition, ModalWholePage, 
} from "../../styles/ModalStyle"
import { FaInfoCircle } from "react-icons/fa";
import ModalPortal from "../../portal/ModalPortal";
import { toast } from "react-toastify";

const BussinesModal = (props) => {
    const [user , setUser]= useState();

    const getData = () =>{
        axios.get(`/point/user/${props?.point_id}`).then((res) => {
            setUser(res?.data?.user);
        }).catch((err) => {
            toast.error('Something went wrong!')
            console.log(err)
        });
    }

    useEffect(()=>{
        getData();
    },[])

    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle deletePoint>
                        <div className="text-center">
                            <FaInfoCircle className='display-3 text-info' />
                            <h3 className='my-3'>User Info</h3>
                            <div className="text-left">
                            <div className="row">
                                <div className="col-md-6 my-1">
                           <h5>Name: {user?.full_name}</h5>

                                </div>
                            <div className="col-md-6 my-1">

                           <h5>Email: {user?.email}</h5>
                            </div>
                            <div className="col-md-12 my-1">

                           <h5>Phone: {user?.phone}</h5>
                            </div>
                            </div>
                            </div>
                        </div>
                        <ModalButtonsPosition className='justify-content-between mt-5'>
                            <ModalMainButtons closeDeleteButton className='w-100' onClick={() => props?.handleModalClose()}>Close</ModalMainButtons>
                        </ModalButtonsPosition>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }
}

export default BussinesModal;