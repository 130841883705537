import React, { useEffect, useState } from 'react';
// import { components } from 'react-select';
import './Loader.scss';


const Loader = () => {

    return (
        <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    )
}
export default Loader;