import React from "react";
import * as RiIcons from "react-icons/ri";

export const Menudata = [
  {
    title: "Dashboard",
    path: "/",
    className: "menuitem",
  },
  {
    title: "Cities",
    path: "/cities",
    className: "menuitem",
  },
  {
    title: "Countries",
    path: "/countries",
    className: "menuitem",
  },
  {
    title: "Points",
    path: '/points',
    className: "menuitem"
  },
  {
    title: "Events",
    path: '/events',
    className: "menuitem"
  },
  {
    title: "Listing Features",
    path: '/features',
    className: "menuitem"
  },
  // {
  //   title: "Tour",
  //   path: "/tour",
  //   className: "menuitem",
  // },
  {
    title: "Transactions",
    path: '/transactions',
    className: "menuitem"
  }, 
  {
    title: "Category Management",
    className: "menuitem",
    // path: "",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Categories",
        path: "/pointcategories",
        className: "menuitem",
      },
      {
        title: "Sub-Categories",
        path: "/point-subcategories",
        className: "menuitem",
      },
     ]
  },
  {
    title: "Language Management",
    className: "menuitem",
    // path: "",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Languages",
        path: "/languages",
        className: "menuitem",
      },
      {
        title: "Labels",
        path: "/labels",
        className: "menuitem",
      },
      {
        title: "Label Languages",
        path: "/labellang",
        className: "menuitem",
      },
    ]
  },
  {
    title: "User Management",
    className: "menuitem",
    // path: "",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "User Roles",
        path: "/userroles",
        className: "menuitem",
      },
      {
        title: "Users",
        path: "/users",
        className: "menuitem",
      },
      {
        title: "Approvals",
        path: "/approvals",
        className: "menuitem",
      },
    ]
  },
  {
    title: "Administration",
    className: "menuitem",
    // path: "",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Data Types",
        path: "/datatypes",
        className: "menuitem",
      },
    ],
  }
];
