import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const EditUser = (props) => {

    const [userdata, adduserdata] = useState({});
    const [userroles, adduserroles] = useState([]);
    const [languages, addlanguages] = useState([]);
    const [city, setCity] = useState([]);
    const [isloading, setisloading] = useState(false);


    useEffect(() => {
        // defValues();
        axios.get('user-roles/all')
            .then((data) => {
                adduserroles(data.data)
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
        axios.get('languages/all')
            .then((data) => {
                addlanguages(data.data);
                setisloading(true)
            }).catch(err => {
            toast.error('Something went wrong!')
            console.log(err)
        });
        axios.get('cities/all').then((data) => {
            setCity(data?.data);
        }).catch(err => {
            toast.error('Something went wrong!')
            console.log(err)
        });
        axios.get('users/id?id=' + props.match.params.id)
            .then((data) => {
                adduserdata(data.data)
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
     
    }, [])

// const defValues = () => {
//     const ud = Object.assign({}, userdata);
//     ud.auth = 'initial-auth';
//     ud.mobileauth = 'initial-auth';
//     adduserdata(ud);
// }

    const full_name = e => {
        const ud = Object.assign({}, userdata);
        ud.full_name = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const email = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.email = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const pass = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.pass = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const phone = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.phone = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const def_lang = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.def_lang = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const city_id = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.city_id = Number(e.target.value)
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const role = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.role = Number(e.target.value);
        adduserdata(ud);
        // console.log('seeme',userdata)
    }
    const active = e => {
        // console.log(e)
        const ud = Object.assign({}, userdata);
        ud.active = e.target.value
        adduserdata(ud);
        // console.log('seeme',userdata)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put('users', userdata)
            .then(data => {
                toast.success('User has been edited successfully!')
                console.log(data);
                window.history.back();
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });

    }


    return (
        <div className='users p-5 text-left'>
            <div className='row'><h1>Edit User</h1></div>
            {!isloading ? '' :
                <form onSubmit={handleSubmit}>
                    <input type='text' placeholder='full name' onChange={full_name}
                           defaultValue={userdata?.full_name}></input>
                    <input type='email' placeholder='email' onChange={email} defaultValue={userdata.email}></input>
                    <input type='password' placeholder='your password' disabled onChange={pass}
                           defaultValue={userdata.pass}></input>
                    <input type='text' placeholder='phone' onChange={phone} defaultValue={userdata.phone}></input>
                    <select onChange={def_lang} value={userdata?.def_lang}>
                        {/* <option disabled selected>Select</option> */}
                        {languages?.map((el) => {
                            return (<option value={el?.id}>{el?.name}</option>)
                        })}

                    </select>
                    <select onChange={role} value={userdata.role}>
                        {/* <option disabled selected>Select</option> */}
                        {userroles?.map((el) => {
                            return (
                                <>
                                <option value={el?.id}>{el?.role_name}</option>
                                </>
                            )
                        })}

                    </select>
                    <select onChange={active} value={userdata?.active}>
                        {/* <option >Select</option> */}
                        <option value={false}>Passive</option>
                        <option value={true}>Active</option>


                    </select>
                    <select 
                            title="This field is required" onChange={city_id} value={userdata?.city_id}>
                        <option value="" selected>Select</option>
                        {city?.map((el) => {
                            return (<option value={el?.id}>{el?.name}</option>)
                        })}

                    </select>
                    <button type='submit' className="buttonAction">Edit User</button>
                </form>
            }
        </div>

    )
}
export default EditUser;
