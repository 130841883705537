import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const AddDataType = (props) => {

    const [datatypes, adddatatypes] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const datatypesName = e => {
        console.log(e)
        const ud = Object.assign({}, datatypes);
        ud.name = e.target.value
        adddatatypes(ud);
    }

    const datatypesType = e => {
        console.log(e)
        const ud = Object.assign({}, datatypes);
        ud.type = e.target.value
        adddatatypes(ud);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if(!submitting) {
            setSubmitting(true);
        axios.post('data-types', datatypes)
            .then(data => {
                console.log(data);
                toast.success('Data Type has been added successfully!')
                window.history.back();
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            }).finally(() => {
                setSubmitting(false);
              })}

    }

    return (
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Add datatypes</h1></div>
            <form onSubmit={handleSubmit}>
                <input type='text' required
                       title="This field is required" placeholder='Name' onChange={datatypesName}></input>
                <input type='text' required
                       title="This field is required" placeholder='Type' onChange={datatypesType}></input>


                <button type='submit' className="buttonAction" disabled={submitting}>Add datatypes</button>
            </form>
        </div>

    )
}
export default AddDataType;
