import logo from './logo.svg';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
} from 'react-router-dom';
import Auth from './Auth';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Dashboard from './components/dashboard/dashboard';
import Cities from './components/cities/cities';
import Sidebar from './components/header/sidebar';
import AddCity from './components/cities/add';
import EditCities from './components/cities/edit';
import DeleteCity from './components/cities/delete';
import Countries from './components/countries/countires';
import AddCountry from './components/countries/add';
import EditCountry from './components/countries/edit';
import DeleteCountry from './components/countries/delete';
import DataTypes from './components/data_types/dataTypes';
import AddDataType from './components/data_types/add';
import EditDataTypes from './components/data_types/edit';
import DeleteDatatype from './components/data_types/delete';
import Languages from './components/languages/languages';
import AddLanguages from './components/languages/add';
import EditLanguage from './components/languages/edit';
import DeleteLanguages from './components/languages/delete';
import Labels from './components/labels/labels';
import AddLabels from './components/labels/add';
import EditLable from './components/labels/edit';
import DeleteLabel from './components/labels/delete';
import LabelLanguages from './components/label_languages/label_languages';
import AddLabelLang from './components/label_languages/add';
import EditLabelLang from './components/label_languages/edit';
import DeleteLabelLang from './components/label_languages/delete';
import PointCategories from './components/point_categories/point_categories';
import AddPointCategories from './components/point_categories/add';
import EditPointCategories from './components/point_categories/edit';
import DeletePointCategories from './components/point_categories/delete';
import PointSubCategories from './components/point_subcategories/point_subcategories';
import AddPointSubCategory from './components/point_subcategories/add';
import EditPointSubCategory from './components/point_subcategories/edit';
import DeletePointSubCategory from './components/point_subcategories/delete';
import UserRoles from './components/user_roles/user_roles';
import AddUserRole from './components/user_roles/add';
import EditUserRole from './components/user_roles/edit';
import DeleteUserRole from './components/user_roles/delete';
import Users from './components/users/users';
import AddUser from './components/users/add';
import EditUser from './components/users/edit';
import Login from './components/login/login';
import BkpPoints from './components/points/bkp-Points'
import Points from './components/points/points';
import AddPoints from './components/points/add';
import EditPoint from "./components/points/EditPoint";
import EditModal from "./components/points/editModal";
import Transactions from "./components/Transactions/Transactions";
import {ToastContainer} from "react-toastify";
import DeletePoint from './components/points/deletePoint';
import TourPoint from './components/tour/tour';
import EditTour from './components/tour/editTour/editTour';
import EditEvent from './components/events/EditEvent';
import events from './components/events/events';
import ListingFeatures from './components/listing_features/listingFeatures';
import listingFeatures from './components/listing_features/listingFeatures';
import Approvals from './components/users/approval';


function App() {
    return (
        <div className="App">
            <ToastContainer />
            <Router>
                {Auth() ?
                    <>
                        <Header/>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-3'>
                                <Sidebar/>
                            </div>
                            <div className='col-xl-9 col-lg-9 col-md-12 col-sm-12'>
                                <div className='mycontent'>
                                    <Switch>
                                        <Route exact path="/" component={Dashboard}/>
                                        <Route exact path="/cities" component={Cities}/>
                                        <Route exact path="/cities/add" component={AddCity}/>
                                        <Route exact path="/cities/edit/:id" component={EditCities}/>
                                        <Route exact path="/cities/delete/:id" component={DeleteCity}/>
                                        <Route exact path="/countries" component={Countries}/>
                                        <Route exact path="/countries/add" component={AddCountry}/>
                                        <Route exact path="/countries/edit/:id" component={EditCountry}/>
                                        <Route exact path="/countries/delete/:id" component={DeleteCountry}/>
                                        <Route exact path="/datatypes" component={DataTypes}/>
                                        <Route exact path="/datatypes/add" component={AddDataType}/>
                                        <Route exact path="/datatypes/edit/:id" component={EditDataTypes}/>
                                        <Route exact path="/datatypes/delete/:id" component={DeleteDatatype}/>
                                        <Route exact path="/languages" component={Languages}/>
                                        <Route exact path="/languages/add" component={AddLanguages}/>
                                        <Route exact path="/languages/edit/:id" component={EditLanguage}/>
                                        <Route exact path="/languages/delete/:id" component={DeleteLanguages}/>
                                        <Route exact path="/labels" component={Labels}/>
                                        <Route exact path="/labels/add" component={AddLabels}/>
                                        <Route exact path="/labels/edit/:id" component={EditLable}/>
                                        <Route exact path="/labels/delete/:id" component={DeleteLabel}/>
                                        <Route exact path="/labellang" component={LabelLanguages}/>
                                        <Route exact path="/labellang/add" component={AddLabelLang}/>
                                        <Route exact path="/labellang/edit/:id" component={EditLabelLang}/>
                                        <Route exact path="/labellang/delete/:id" component={DeleteLabelLang}/>
                                        <Route exact path="/pointcategories" component={PointCategories}/>
                                        <Route exact path="/pointcategories/add" component={AddPointCategories}/>
                                        <Route exact path="/pointcategories/edit/:id" component={EditPointCategories}/>
                                        {/* <Route exact path="/pointcategories/delete/:id" component={DeletePointCategories}/> */}
                                        <Route exact path="/point-subcategories" component={PointSubCategories}/>
                                        <Route exact path="/point-subcategories/add" component={AddPointSubCategory}/>
                                        <Route exact path="/point-subcategories/edit/:id" component={EditPointSubCategory}/>
                                        <Route exact path="/point-subcategories/delete/:id" component={DeletePointSubCategory}/>
                                        <Route exact path="/userroles" component={UserRoles}/>
                                        <Route exact path="/userroles/add" component={AddUserRole}/>
                                        <Route exact path="/userroles/edit/:id" component={EditUserRole}/>
                                        <Route exact path="/userroles/delete/:id" component={DeleteUserRole}/>
                                        <Route exact path="/users" component={Users}/>
                                        <Route exact path="/users/add" component={AddUser}/>
                                        <Route exact path="/users/edit/:id" component={EditUser}/>
                                        <Route exact path="/points" component={Points}/>
                                        <Route exact path="/points/add-point" component={AddPoints}/>
                                        <Route exact path="/points/edit-point/:id" component={EditPoint} />
                                        <Route exact path="/transactions" component={Transactions} />
                                        <Route exact path="/points/delete/:id" component={DeletePoint} />
                                        <Route excat path="/edit-event/:idParams" component={EditEvent} />
                                        <Route excat path="/events" component={events} />
                                        <Route excat path="/features" component={listingFeatures} />
                                        <Route excat path="/approvals" component={Approvals} />
                                        {/* <Route exact path="/tour" component={TourPoint} />
                                        <Route path="/edit-tour/:idParams" component={EditTour} /> */}
                                        {/* <Route exact path="/users/delete/:id" component={DeleteUser} /> */}

                                    </Switch>
                                </div>
                                {/* <Footer /> */}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <Redirect exact to="/login"/>
                        <Route exact path="/login" component={Login}/>
                        {/* <Route exact path="/forgot-password" component={ForgotPassword}/> */}
                    </>
                }
            </Router>
        </div>
    );
}

export default App;
