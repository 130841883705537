import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import closeIcn from "../../Images/close.svg";
import { Link } from "react-router-dom";

import Modal from "react-modal";

const EditCities = (props) => {
  const [city, addCity] = useState([]);
  const [imageDatas, setImageDatas] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [mapState, setMapState] = useState([0, 0]);
  const [center, setCenter] = useState([0, 0]);
  const [located, setLocated] = useState(false);
  const [languages, addlanguages] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [audio, setAudio] = useState(null);
  const [imgError, setImgError] = useState("");
  const [singleData, setSingleData] = useState([]);

  const [addContent, setAddContent] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [image, setImage] = useState(null);
  const [imagee, setImagee] = useState();

  const [contentModal, setContentModal] = useState();
  const [audioModal, setAudioModal] = useState();
  const [imageModal, setImageModal] = useState();

  const [editContentModal, setEditContentModal] = useState();
  const [modalIsOpenAudio6, setIsOpenAudio6] = useState();
  const [modalIsOpenImage7, setIsOpenImage7] = useState();

  const [modalIsOpenDelete, setModalIsOpenDelete] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [successDeletedMsg, setSuccessDeletedMsg] = useState("");


  let cId = "";
  const idd = props.match.params.id;
  
  useEffect(async () => {
    await getDescriptionData();
    await getAudioData();
    await getImageData();

    axios
      .get("languages/all")
      .then((data) => {
        addlanguages(data.data);
        // setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, [idd, singleData]);

  
  function closeContentModal(){
    setContentModal(!contentModal)
  }

  function closeAudioModal(id){
    getSingleAudio(id);
    setAudioModal(!audioModal)
  }

  function closeImageModal(){
    setImageModal(!imageModal);
  }


  const getDescriptionData = async () => {
    try {
      let response = await axios.get(`city-data/description/${idd}`);
      setAddContent(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAudioData = async () => {
    try {
      let response = await axios.get(`city-data/audio/${idd}`);
      console.log("", response?.data);
      setAudioData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadContent = async (event) => {
    event.preventDefault();

    let languageValue = event.target[1].value;
    let content = event.target.content.value;

    try {
      let response = await axios.post(`city-data/description/${idd}`, {
        lang: languageValue,
        content: content,
        city_id: idd
      });
      //   setSuccessCall("Success");
      toast.success("Content uploaded Successfully!");
      getDescriptionData();
      // setTimeout(() => {
        closeContentModal();
      // }, 1000);
      console.log(response);

    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };
  
  const uploadAudioFile = async (e) => {
    e.preventDefault();

    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an audio.");
      setDisabled(true);
      return;
    }

    if (!file.name.match(/\.(mp3|wav|flac)$/)) {
      setImgError("Please select a valid image. (mp3, wav, flac)");
      setDisabled(true);
      return;
    }

    if (file && file.name.match(/\.(mp3|wav|flac)$/)) {
      await setAudio(file);
      setDisabled(false);
    }
  };

  const uploadAudio = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    const audioUpload = new FormData();
    audioUpload.append("audio", audio, audio?.name);
    audioUpload.append("lang", languageValue);
    try {
      let response = await axios.post(`city-data/audio/${idd}`, audioUpload);
      console.log(response);
      //   setSuccessCall("Success");
      toast.success("Audio uploaded Successfully!");
      getAudioData();
      // setTimeout(() => {
        closeAudioModal();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };


// Edit CONTENT
async function openModal5(id) {
  await getSingleAudio(id);
  await setEditContentModal(!editContentModal);
}

const editContent = async (event) => {
  event.preventDefault();
  let languageValue = event.target[1].value;
  let content = event.target.content.value;

  let id = singleData?.point_data_id;
  try {
    let response = await axios.put(`city-data/description/${id}`, {
      lang: languageValue,
      content: content,
    });
    //   setSuccessCall("Success");
    getDescriptionData();
    getSingleAudio(cId);
    toast.success("Content has been edited successfully!");
    // setTimeout(() => {
      openModal5();
    // }, 1000);
    console.log(response);
  } catch (error) {
    toast.error("Something went wrong!");
    console.log(error);
  }
};


// Edit AUDIO
async function openModal6(id) {
  await getSingleAudio(id);
  await setIsOpenAudio6(!modalIsOpenAudio6);
}

const getSingleAudio = async (id) => {
  try {
    let response = await axios.get(`city-data/single/${id}`);
    setSingleData(response?.data);
  } catch (error) {
    console.log(error);
  }
};

const editAudio = async (event) => {
  event.preventDefault();

  let languageValue = event.target[1].value;
  const audioUpload = new FormData();

  let id = singleData?.point_data_id;

  audioUpload.append("audio", audio, audio?.name);
  audioUpload.append("lang", languageValue);

  try {
    let response = await axios.put(`city-data/audio/${id}`, audioUpload);
    getSingleAudio(cId);
    getAudioData();
    //   setSuccessCall("Success");
    toast.success("Audio has been edited successfully!");
    console.log(response);
    // setTimeout(() => {
      openModal6();
    // }, 1000);
  } catch (error) {
    toast.error("Something went wrong!");
    console.log(error);
  }
};


// Edit Image
function openModal7(id) {
  getSingleAudio(id);
  setIsOpenImage7(!modalIsOpenImage7);
}

const editImage = async (event) => {
  event.preventDefault();

  let languageValue = event.target[1].value;
  const imageUpload = new FormData();

  imageUpload.append("image", image, image?.name);
  imageUpload.append("lang", languageValue);

  let id = singleData?.point_data_id;

  try {
    let response = await axios.put(`city-data/image/${id}`, imageUpload);

    getSingleAudio(cId);
    getImageData();
    //   setSuccessCall("Success");
    toast.success("Image has been edited successfully!");
    // setTimeout(() => {
      openModal7();
    // }, 1000);
  } catch (error) {
    toast.error("Something went wrong!");
    console.log(error);
  }
};


// DELETE
 function openConfirmModal(id) {
  console.log("idddddddddd", id);
  setDeleteId(id);
  setModalIsOpenDelete(!modalIsOpenDelete);
}
const confirmButton = (event, id) => {
  console.log("delete id ", deleteId);
  console.log("log incoming", id);
  id = id;
  if (id == 1) {
    deleteImage(event, deleteId);
    getImageData();
    getAudioData();
    getDescriptionData();
    setSuccessDeletedMsg("Has been deleted successfully");
    // setTimeout(() => {
      openConfirmModal();
    // }, 2000);
  } else {
    console.log("nuk u fsih kurgjo");
    // setTimeout(() => {
      openConfirmModal();
    // }, 1000);
  }
};

const deleteImage = async (event, id) => {
  event.preventDefault();

  try {
    let response = await axios.delete(`city-data/?id=${id}`);
    console.log(response);
    getSingleAudio(cId);
    getImageData();
    toast.success("Content has been deleted successfully!");
  } catch (error) {
    toast.error("Something went wrong!");
    console.log(error);
  }
};



  let map;

  const stateName = (e) => {
    console.log(e);
    const ud = Object.assign({}, city);
    ud.country_id = e.target.value;
    addCity(ud);
  };

  const cityName = (e) => {
    console.log(e);
    const ud = Object.assign({}, city);
    ud.name = e.target.value;
    addCity(ud);
  };

  const comingSoon = (e) => {
    const ud = Object.assign({}, city);
    ud.coming_soon = Number(e.target.value);
    addCity(ud);
  };

  const orderNumber = (e) => {
    const ud = Object.assign({}, city);
    ud.order_nr = e.target.value;
    addCity(ud);
  };

  const getCurrentCity = async () => {
    await axios
      .get("cities/id?id=" + props.match.params.id)
      .then((data) => {
        addCity(data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    getCurrentCity();
    // getLol();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("cities/", city)
      .then((data) => {
        console.log(data.data);
        toast.success("City has been edited successfully!");
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const imageDataChange = (e) => {
    const ud = Object.assign({}, imageData);
    ud.image = e.target.files[0];
    setImageData(ud);
    console.log("fajllimrena", imageData);
  };

  function MyComponent() {
    // useEffect(()=>{
    //     map.locate();
    // },[])
    map = useMapEvents({
      // whenReady: () => {
      //     console.log('loaded')
      //     locationCenter();
      // },
      click: async (e) => {
        let i = await map.mouseEventToLatLng(e.originalEvent);
        await setMapState([Number(i.lat), Number(i.lng)]);
        console.log(map);
        await latLongFunc([Number(i.lat), Number(i.lng)]);
        // await latitude(i.lat);
        //  latitude(i.lat).then(()=>longitude(i.lng));
        // setTimeout(()=>latitude(i.lat), 105);
        // console.log('shop data inside click', shopData)
        // map.locate();
      },
      locationfound: async (location) => {
        console.log(location);
        console.log("location found:", [
          location.latlng.lat,
          location.latlng.lng,
        ]);
        //
        // if (mapState[0] === 0 && mapState[1] === 0) {
        //     setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
        //     setLocated(true);
        //     await map.flyTo([Number(location.latlng.lat), Number(location.latlng.lng, {
        //         animate: false
        //     })]);
        //     await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)]);
        //
        // }

        if (city.longitude === 0 && city.latitude === 0) {
          await latLongFunc([location.latlng.lat, location.latlng.lng]);
          await map.panTo([
            Number(city?.latitude),
            Number(city?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([Number(city?.latitude), Number(city?.longitude)]);
        }

        if (city != "") {
          // console.log('po hin te shopi', ([Number(shopData?.latitude), Number(location?.longitude)]));
          // console.log('centraaaa', [Number(shopData?.latitude), Number(location?.longitude)]);
          // using flyTo instead of panTo, it has some animation in it
          await map.panTo([
            Number(city?.latitude),
            Number(city?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([Number(city?.latitude), Number(city?.longitude)]);
        } else {
          // console.log('po hin te location defauklt')
          // using flyTo instead of panTo, it has some animation in it
          await map.panTo([
            Number(location.latlng.lat),
            Number(location.latlng.lng, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(location.latlng.lat),
            Number(location.latlng.lng),
          ]);
        }
        // );
        // setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
        setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
        setLocated(true);
        map.stopLocate();
        // console.log(mapState)
      },
    });
    if (located == false) {
      map.locate();
    }
    return null;
  }
  const latLongFunc = async (e) => {
    const ud = Object.assign({}, city);
    ud.latitude = e[0];
    ud.longitude = e[1];
    console.log("inside latitude", ud);
    await addCity(ud);
  };
  const latitude = (e) => {
    const ud = Object.assign({}, city);
    ud.latitude = e.target.value;
    addCity(ud);
  };
  const longitude = (e) => {
    const ud = Object.assign({}, city);
    ud.longitude = e.target.value;
    addCity(ud);
  };

  const saveImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageData.image);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("cities/image/" + props.match.params.id, formData, config)
      .then((res) => {
        console.log(res.data);
        toast.success("Image has been changed successfully!");
        // console.log('image',formData)
        // window.location = '/ZZ'
        setImageData(null)
        getCurrentCity();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
    // getStore();
    // window.location.reload();
  };


  // ADD IMAGE
  const getImageData = async () => {
    try {
      let response = await axios.get(`city-data/image/${idd}`);
      setImageDatas(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImagee(URL.createObjectURL(e.target.files[0]));
    }

    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an image.");
      setDisabled(true);
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgError("Please select a valid image. (jpg, jpeg, png)");
      setDisabled(true);
      return false;
    }

    if (file && file.name.match(/\.(jpg|jpeg|png)$/)) {
      await setImage(file);
      setDisabled(false);
    }
  };

  const uploadImage = async (event) => {

    event.preventDefault();
    let languageValue = event.target[1].value;
    const imageUpload = new FormData();
    imageUpload.append("image", image, image?.name);
    imageUpload.append("lang", languageValue);
   
    try {
      let response = await axios.post(`city-data/image/${idd}`, imageUpload);
      console.log(response);
      //   setSuccessCall("Success");
      toast.success("Image uploaded Successfully!");
      getImageData();
      setImagee()
      // setTimeout(() => {
        closeImageModal();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };
  console.log("City:", city)

 

  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Edit City</h1>
      </div>
      <form onSubmit={handleSubmit} className="w-100">
        <div className="row mb-5">
          <div className="col-md-12">
            <MapContainer center={center} zoom={13} scrollWheelZoom={true}>
              <MyComponent />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {city != "" ? (
                <Marker position={[city?.latitude, city?.longitude]}>
                  <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              ) : mapState != "" ? (
                <Marker position={[mapState[0], mapState[1]]}>
                  <Popup>
                    Pointer. <br /> Pointer.
                  </Popup>
                </Marker>
              ) : (
                ""
              )}
            </MapContainer>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6">

          <img
            src={`${process.env.REACT_APP_UPLOADS + city.image_url}`}
            alt="City Image"
            className="mx-auto w-100"
            // width="500px"
            height="300px"
          />

        <input type="file" name="image" className="border mt-3" style={{cursor:"pointer"}} onChange={imageDataChange}></input>
        {!!imageData && <button className="btn-success w-100" onClick={saveImage}>Save Image</button>}
        </div>
        <div className="col-md-6">
        <h1 className="text-center">{city.name}</h1>
        <span className="p-2 text-muted">Country</span>
        <select
          required
          className="mt-2 form-control"
          title="This field is required"
          onChange={stateName}
          value={city.country_id}
        >
 

          <option value={null} disabled>
            Selekto
          </option>
          <option value={1}>Kosove</option>
        </select>
        <span className="p-2 text-muted">Name</span>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="City Name"
          onChange={cityName}
          value={city.name}
          className="form-control"
        ></input>
        <span className="p-2 text-muted">Latitude</span>
        <input
          type="text"
          step=".0001"
          className="col-md-12 input__button form-control"
          value={city["latitude"] ?? " "}
          // onChange={latitude}
          // disabled={!isShopEdit}
        />
        <span className="p-2 text-muted">Longitude</span>
        <input
          type="text"
          step=".0001"
          className="col-md-12 input__button form-control"
          value={city["longitude"] ?? " "}
          // onChange={longitude}
          // disabled={!isShopEdit}
        />
        <span className="p-2 text-muted">Coming Soon</span>
           <select className="form-control" value={city?.coming_soon ? 1 : 0} onChange={comingSoon}>
            <option value="" disabled>Select Status</option>
            <option value={1}>Active</option>
            <option value={0}>Passive</option>
          </select>
          <span className="p-2 text-muted">Order Number</span>
          <input
          className="form-control"
          type="number"
          placeholder="Order"
          value={city?.order_nr}
          onChange={orderNumber}
        ></input>
        <button type="submit" className="buttonAction">Edit City</button>
        </div>
        </div>
      </form>
      <div className="row buttons-group">

        {/* CONTENT */}
        <div className="col-md-12 mb-3">
          <button className="btn-plus " onClick={closeContentModal}>
            <i className="fas fa-plus"></i>
          </button>
          <h2>Content</h2>
          <div className="row">
            <div className="col-md-12">
            {addContent?.map((el, i) => {
                  return (
                    <div className="listimi showme">
                      <div className="row">
                        <div className="col-7">{el?.content}</div>
                        <div className="col-2">{el?.lang}</div>
                        {/*<div className='col-2'>test</div>*/}
                        {/*<div className='col-2'>test</div>*/}
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-edit"}
                            onClick={() => openModal5(el?.point_data_id)}
                          ></Link>
                        </div>
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-trash"}
                            onClick={() => openConfirmModal(el?.point_data_id)}
                          ></Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* AUDIO */}
        <div className="col-md-12 mb-3">
            <button className="btn-plus" onClick={closeAudioModal}>
              <i className="fas fa-plus"></i>
            </button>
            <h2>Audio</h2>
            <div className="row">
              <div className="col-md-12">
                 {audioData?.map((el, i) => {
                  return (
                    <div className="listimi showme">
                      <div className="row">
                      <div className="col-3">{el?.data_type_name}</div>
                        <div className="col-3">{el?.data_type}</div>
                        <div className="col-3">{el?.lang}</div>
                        {/*<div className='col-2'>test</div>*/}
                        {/*<div className='col-2'>test</div>*/}
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-edit"}
                            onClick={() => openModal6(el?.point_data_id)}
                          ></Link>
                        </div>
                        <div className="col-1 hideme">
                          <Link
                            className={"fa fa-trash"}
                            onClick={() => openConfirmModal(el?.point_data_id)}
                          ></Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          
        </div>

        {/* IMAGE */}
        <div className="col-md-12 mb-3">
            <button className="btn-plus" onClick={closeImageModal}>
              <i className="fas fa-plus"></i>
            </button>
            <h2>Image</h2>
            <div className="row">
            {imageDatas?.map((el, i) => {
                // console.log('audio audio audio', process.env.REACT_APP_UPLOADS + el.url)
                return (
                  <div className="col-md-4 showme">
                    <img
                      src={process.env.REACT_APP_UPLOADS + el?.url}
                      className="card-img-top image-part"
                      alt="..."
                      width={100} height={100}
                      style={{objectFit: "cover"}}
                    />
                    <div className="col-1 hidemee">
                      <Link
                        className={"fa fa-edit"}
                        onClick={() => openModal7(el?.point_data_id)}
                      ></Link>
                    </div>
                    <div className="col-1 hidemee">
                      <Link
                        className={"fa fa-trash"}
                        onClick={() => openConfirmModal(el?.point_data_id)}
                      ></Link>
                    </div>
                  </div>
                );
              })}
            </div>
        </div>
  
      </div>


        {/* EDIT CONTENT MODAL */}
        {editContentModal ? (
          <div className="row modal-table">
            <div className="col-md-12">
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}

              
            {/* EDIT CONTENT MODAL */}
              <Modal
                isOpen={editContentModal}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={openModal5}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  
                  <div className="modal-header">
                    <p>Edit Content</p>
                    <button onClick={() => openModal5()}><img src={closeIcn} width={"12.5px"} /></button>
                  </div>
               
                  <div className="col-md-12">
                    <form onSubmit={editContent} className="w-100">
                      <div className="mb-3 form-modal">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Content:
                        </label>
                        <textarea
                          className="form-control"
                          name="content"
                          defaultValue={singleData?.content}
                          id="exampleFormControlTextarea1"
                          title="This field should not be left blank."
                          required
                          rows="3"
                        ></textarea>
                      </div>
                      <p>{imgError}</p>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                          >
                            Options
                          </label>
                        </div>
                        <select
                          className="custom-select"
                          defaultValue={singleData?.lang_id}
                          id="inputGroupSelect01"
                          name="lang_id"
                          title="This field should not be left blank."
                          required
                        >
                          <option
                            name="lang_id"
                            disabled
                            // defaultValue={singleData?.lang_id}
                            selected
                          >
                            Select Language
                          </option>
                          {languages?.map((el) => {
                            return (
                              <option value={Number(el.id)}>{el.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="button-left">
                      <button
                        className="btn btn-outline-secondary submit-button"
                        type="submit"
                        disabled={isDisabled}
                        id="inputGroupFileAddon03"
                      >
                        Edit Content
                      </button>
                      </div>
                      {/* <p className="validation-msg-modal">successCall</p> */}
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* EDIT AUDIO MODAL */}
        {modalIsOpenAudio6 ? (
          <div className="row modal-table">
            <div className="col-md-12">
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}

              {/* EDIT AUDIO MODAL */}
              <Modal
                isOpen={modalIsOpenAudio6}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={openModal6}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  <div className="modal-header">
                    <p>Edit Audio</p>
                    <button onClick={() => openModal6()}><img src={closeIcn} width={"12.5px"} /></button>
                  </div>
                  <div className="col-md-12">
                    {/* <h1 className="title-part">Edit Audio</h1> */}
                    <div className="audio-player">
                      <audio controls>
                        <source
                          src={process.env.REACT_APP_UPLOADS + singleData?.url}
                          type="audio/ogg"
                        />
                      </audio>
                    </div>
                    <form onSubmit={editAudio}>
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile03"
                          name="audio"
                          onChange={uploadAudioFile}
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          required
                          title="This field should not be left blank."
                        />
                      </div>
                      <p>{imgError}</p>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                          >
                            Options
                          </label>
                        </div>
                        <select
                          className="custom-select"
                          defaultValue={singleData?.lang_id}
                          id="inputGroupSelect01"
                          title="This field should not be left blank."
                          required
                        >
                          <option name="lang" disabled selected>
                            Select Language
                          </option>
                          {languages?.map((el) => {
                            return (
                              <option value={Number(el?.id)}>{el?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <p></p>
                      <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={isDisabled}
                          id="inputGroupFileAddon03"
                        >
                          Upload Audio
                        </button>
                      </div>
                      {/* <p className="validation-msg-modal">{successCall}</p> */}
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* EDIT IMAGE MODAL  */}
        {modalIsOpenImage7 ? (
          <div className="row audio-part">
            <div className="col-md-12">
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}

              {/* EDIT IMAGE MODAL */}
              <Modal
                isOpen={modalIsOpenImage7}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={openModal7}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  <div className="modal-header">
                    <p>Edit Image</p>
                    <button onClick={() => openModal7()}><img src={closeIcn} width={"12.5px"} /></button>

                  </div>

                  <div className="col-md-12">
                    <form onSubmit={editImage}>
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile03"
                          name="image"
                          onChange={uploadFile}
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          title="This field should not be left blank."
                          required
                        />
                      </div>
                      <p>{imgError}</p>
                  
                      <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={false}
                          id="inputGroupFileAddon03"
                        >
                          Upload Image
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}


      {/* ADD CONTENT MODAL  */}
      <div className="row modal-table">
        <div className="col-md-12">
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}
          <Modal
            isOpen={contentModal}
            // onAfterOpen={afterOpenModal2}
            onRequestClose={closeContentModal}
            className="mymodal"
            contentLabel="Example Modal"
          >
            <div className="row audio-part">

              <div className="modal-header">
                <p>Content</p>
                <button onClick={() => closeContentModal()}><img src={closeIcn} width={"12.5px"} /></button>
              </div>
              <div className="col-md-12">
                {/* <h1 className="title-part">Content</h1> */}
                <form onSubmit={uploadContent}>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Example textarea
                    </label>
                    <textarea
                      className="form-control"
                      name="content"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                  <p>{imgError}</p>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <label
                        className="input-group-text"
                        htmlFor="inputGroupSelect01"
                      >
                        Options
                      </label>
                    </div>
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      title="This field should not be left blank."
                      required
                    >
                      <option name="lang" disabled selected>
                        Select Language
                      </option>
                      {languages?.map((el) => {
                        return <option value={Number(el.id)}>{el.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="button-left">
                    <button
                      className="btn btn-outline-secondary submit-button"
                      type="submit"
                      id="inputGroupFileAddon03"
                    >
                      Upload
                    </button>
                    </div>
                  {/* <p className="validation-msg-modal">{successCall}</p> */}
                </form>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      
      {/* ADD AUDIO MODAL */}
      <div className="row modal-table">
        <div className="col-md-12">
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}

          <Modal
            isOpen={audioModal}
            // onAfterOpen={afterOpenModal2}
            onRequestClose={closeAudioModal}
            className="mymodal"
            contentLabel="Example Modal"
          >
            <div className="row audio-part">

              <div className="modal-header">
                <p>Content</p>
                <button onClick={() => closeAudioModal()}><img src={closeIcn} width={"12.5px"} /></button>
              </div>
              <div className="col-md-12">
                {/* <h1 className="title-part">Content</h1> */}
                <form onSubmit={uploadAudio}>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile03"
                        name="audio"
                        onChange={uploadAudioFile}
                        aria-describedby="inputGroupFileAddon03"
                        aria-label="Upload"
                        title="This field should not be left blank."
                        required
                      />
                    </div>
                    <p>{imgError}</p>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect01"
                        >
                          Options
                        </label>
                      </div>
                      <select
                        className="custom-select"
                        id="inputGroupSelect01"
                        title="This field should not be left blank."
                        required
                      >
                        <option name="lang" disabled selected>
                          Select Language
                        </option>
                        {languages?.map((el) => {
                          return (
                            <option value={Number(el.id)}>{el.name}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="button-left">
                      <button
                        className="btn btn-outline-secondary submit-button"
                        // disabled={isDisabled}
                        type="submit"
                        id="inputGroupFileAddon03"
                      >
                        Upload
                      </button>
                    </div>
                    {/* <p className="validation-msg-modal">{successCall}</p> */}
                  </form>
              </div>
            </div>
          </Modal>
        </div>
      </div>

      {/* ADD IMAGE MODAL */}
      <div className="row modal-table">
        <div className="col-md-12">
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2> */}

          <Modal
            isOpen={imageModal}
            // onAfterOpen={afterOpenModal2}
            onRequestClose={closeImageModal}
            className="mymodal"
            contentLabel="Example Modal"
          >
            <div className="row audio-part">

              <div className="modal-header">
                <p>Content</p>
                <button onClick={() => closeImageModal()}><img src={closeIcn} width={"12.5px"} /></button>
              </div>
              <div className="col-md-12">

                <form onSubmit={uploadImage}>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile03"
                        name="image"
                        onChange={uploadFile}
                        aria-describedby="inputGroupFileAddon03"
                        aria-label="Upload"
                        title="This field should not be left blank."
                        required
                      />
                    </div>
                    <p>{imgError}</p>
          
                    <div className="button-left">
                      <button
                        className="btn btn-outline-secondary submit-button"
                        disabled={isDisabled}
                        type="submit"
                        id="inputGroupFileAddon03"
                      >
                        Upload
                      </button>
                    </div>
 
                  </form>
              </div>
            </div>
          </Modal>
        </div>
      </div>


      {/* DELETE MODAL */}
      {modalIsOpenDelete ? (
          <div className="row ">
            <div className="col-md-12">

              {/* DELETE MODAL */}
              <Modal
                isOpen={modalIsOpenDelete}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={openConfirmModal}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row content-part audio-part">
                  
                  <div className="modal-header">
                    <p>Delete This?</p>
                    <button onClick={() => openConfirmModal()}><img src={closeIcn} width={"12.5px"} /></button>

                  </div>

                  <div className="col-md-12 delete-modal">
                    {/* <h1>Delete This?</h1> */}
                    <h1></h1>
                    <button
                      className="btn btn-outline-secondary confirm-button"
                      type="submit"
                      onClick={(event) => confirmButton(event, 1)}
                      id="inputGroupFileAddon03"
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-outline-secondary confirm-button"
                      type="submit"
                      onClick={(event) => confirmButton(event, 0)}
                      id="inputGroupFileAddon03"
                    >
                      No
                    </button>
                    {/* <p className="validation-msg-modal">{successDeletedMsg}</p> */}
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}

    </div>
  );
};
export default EditCities;
