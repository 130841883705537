import React, {useEffect,useState} from 'react';
import { toast } from "react-toastify";
import axios from '../../axios';

const DeleteLabelLang = (props) => {

const [labellang, addlabellang] = useState({});
const [languages, addlanguages] = useState({});
const [labels, addlabels] = useState({});
const [isloading, setisloading] = useState(false);


useEffect(()=>{
    axios.get('label-lang/id?id='+ props.match.params.id).then((data) => {addlabellang(data.data);}).catch(err => console.log(err));
    axios.get('languages/all').then(data => addlanguages(data.data)).catch(err =>  console.log(err));
    axios.get('labels/all').then((data) => {addlabels(data.data); setisloading(true)}).catch(err =>  console.log(err));
},[])






const handleSubmit = (e) => {
    e.preventDefault();
    axios.delete('label-lang/?id='+ labellang.id )
        .then(data => {
            toast.success("Langugage Label has been deleted successfully!");
        window.history.back();
        })
        .catch(err => console.log(err));

}
console.log(languages);
console.log(labels);

    return(
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Delete Label Language</h1></div>
            <div className='row mb-3'>By clicking delete button you agree to delete label language translation for the following data:</div>
            {!isloading ? '' :
            <form onSubmit={handleSubmit}>
                <select  defaultValue={labellang.lable_id} disabled>
                    {/* <option disabled selected>Select</option> */}
                    {labels?.map((el,index)=> {
                        return (<option value={el.id} key={index}>{el.name}</option>)
                    })}

                </select>
                <input type='text' required
                       title="This field is required" placeholder='Content'  defaultValue={labellang.content} disabled />
                <select  defaultValue={labellang.lang_id} disabled>
                    {/* <option disabled selected>Select</option> */}
                    {languages?.map((el,index)=> {
                        return <option value={el.id} key={index}>{el.name}</option>
                    })}

                </select>
                <button type='submit'>Delete Label Language</button>
            </form>
            }
        </div>

    )
}
export default DeleteLabelLang;
