import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const PieChart = ({stats}) => {


    const generateRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 0.6)`;
    };

  const data = {
    labels: stats?.map((el) => el?.option_name + " " + `(${el?.count})`),
    datasets: [{
      label: '# of Votes',
      data: stats?.map((el) => el?.count),
      backgroundColor: stats?.map(() => generateRandomColor()),
      borderColor: stats?.map(() => 'rgba(255, 255, 255, 1)'),
      borderWidth: 1,
    }],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Survey Statistics' },
    },
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
