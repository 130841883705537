import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const EditLabelLang = (props) => {

    const [labellang, addlabellang] = useState({});
    const [languages, addlanguages] = useState({});
    const [labels, addlabels] = useState({});
    const [isloading, setisloading] = useState(false);


    useEffect(() => {
        axios.get('label-lang/id?id=' + props.match.params.id)
            .then((data) => {
                addlabellang(data.data);
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
        axios.get('languages/all')
            .then(data => addlanguages(data.data))
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
        axios.get('labels/all')
            .then((data) => {
                addlabels(data.data);
                setisloading(true)
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
    }, [])

    const labelId = e => {
        const ud = Object.assign({}, labellang);
        ud.lable_id = e.target.value
        addlabellang(ud);
        console.log('seeme', labellang)
    }
    const content = e => {
        // console.log(e)
        const ud = Object.assign({}, labellang);
        ud.content = e.target.value
        addlabellang(ud);
        console.log('seeme', labellang)
    }
    const langID = e => {
        // console.log(e)
        const ud = Object.assign({}, labellang);
        ud.lang_id = e.target.value
        addlabellang(ud);
        console.log('seeme', labellang)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put('label-lang', labellang)
            .then(data => {
                console.log(data);
                toast.success('Language Label has been edited successfully!')
                window.history.back();
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });

    }
    console.log(languages);
    console.log(labels);

    return (
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Edit Label Language</h1></div>
            {!isloading ? '' :
                <form onSubmit={handleSubmit}>
                    <select onChange={labelId} defaultValue={labellang.lable_id}>
                        {/* <option disabled selected>Select</option> */}
                        {labels?.map((el, index) => {
                            return (<option value={el.id} key={index}>{el.name}</option>)
                        })}

                    </select>
                    <input required
                           title="This field is required" type='text' placeholder='Content' onChange={content}
                           defaultValue={labellang.content}></input>
                    <select onChange={langID} defaultValue={labellang.lang_id}>
                        {/* <option disabled selected>Select</option> */}
                        {languages?.map((el, index) => {
                            return <option value={el.id} key={index}>{el.name}</option>
                        })}

                    </select>
                    <button type='submit' className="buttonAction">Edit Label</button>
                </form>
            }
        </div>

    )
}
export default EditLabelLang;
