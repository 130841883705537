// import { arrowFunctionExpression } from "@babel/types";
import { CLEAR_DATA, LOGMEIN, SET_AUTH, SET_USER_ID } from "./types";

const INITIAL_STATE = {
  loggedIn: false,
  auth: null,
  user_id: null,
  user_role: null,
};

const ciceronReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGMEIN:
      return {
        ...state,
        loggedIn: true,
      };
    case SET_USER_ID:
      return { ...state, user_id: action.user_id, user_role: action.user_role };
    case SET_AUTH:
      return { ...state, auth: action.auth };
    case CLEAR_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default ciceronReducer;
