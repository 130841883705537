import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import {toast} from "react-toastify";

const EditPointSubCategory = (props) => {

    const [subcategory, addsubcategory] = useState({});
    const [category, addcategory] = useState({});
// const [labels, addlabels] = useState({});
    const [isloading, setisloading] = useState(false);


    useEffect(() => {
        axios.get('point-subcategories/id?id=' + props.match.params.id)
            .then((data) => {
                addsubcategory(data.data);
            }).catch(err => {
            toast.error('Something went wrong!')
            console.log(err)
        });
        axios.get('point-categories/all')
            .then(data => {
                addcategory(data.data);
                setisloading(true)
            }).catch(err => {
            toast.error('Something went wrong!')
            console.log(err)
        });
        // axios.get('labels/all').then((data) => {addlabels(data.data); setisloading(true)}).catch(err =>  console.log(err));
    }, [])

    const categoryId = e => {
        const ud = Object.assign({}, subcategory);
        ud.category_id = e.target.value
        addsubcategory(ud);
        console.log('seeme', subcategory)
    }
    const name = e => {
        // console.log(e)
        const ud = Object.assign({}, subcategory);
        ud.name = e.target.value
        addsubcategory(ud);
        console.log('seeme', subcategory)
    }
    const isActive = e => {
        // console.log(e)
        const ud = Object.assign({}, subcategory);
        ud.active = e.target.value
        addsubcategory(ud);
        console.log('seeme', subcategory)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put('point-subcategories', subcategory)
            .then(data => {
                console.log(data);
                toast.success('Point Category has been edited successfully!')
                window.history.back();
            })
            .catch(err => {
                toast.error('Something went wrong!')
                console.log(err)
            });
    }
// console.log(category);
// console.log(labels);

    return (
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Edit Sub-Category</h1></div>
            {!isloading ? '' :
                <form onSubmit={handleSubmit}>
                    <select onChange={categoryId} defaultValue={subcategory.category_id}>
                        {/* <option disabled selected>Select</option> */}
                        {category?.map((el, index) => {
                            return (<option value={el.id} key={index}>{el.name}</option>)
                        })}

                    </select>
                    <input type='text' required
                           title="This field is required" placeholder='name' onChange={name} defaultValue={subcategory.name}></input>
                    <select onChange={isActive} defaultValue={subcategory.lang_id}>
                        <option value={1}>Avtive</option>
                        <option value={0}>Passive</option>

                    </select>
                    <button type='submit' className="buttonAction">Edit Subcategory</button>
                </form>
            }
        </div>

    )
}
export default EditPointSubCategory;
