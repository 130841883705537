import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";

const AddLanguages = (props) => {
  const [language, addlanguage] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const languageName = (e) => {
    console.log(e);
    const ud = Object.assign({}, language);
    ud.name = e.target.value;
    addlanguage(ud);
  };

  const languageCode = (e) => {
    console.log(e);
    const ud = Object.assign({}, language);
    ud.code = e.target.value;
    addlanguage(ud);
  };

  const countryCode = (e) => {
    console.log(e);
    const ud = Object.assign({}, language);
    ud.country_code = e.target.value;
    addlanguage(ud);
  };


  const isactive = (e) => {
    console.log(e);
    const ud = Object.assign({}, language);
    ud.active = e.target.value;
    addlanguage(ud);
  };

  // Image
  const imageDataChange = (e) => {
    const ud = Object.assign({}, language);
    ud.image_url = e.target.files[0];
    addlanguage(ud);
    // console.log("fajllimrenaV", selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!submitting) {
      setSubmitting(true);
    const fd = new FormData();
    fd.append("image", language.image_url);
    fd.append("name", language.name);
    fd.append("code", language.code);
    fd.append("country_code", language.country_code);
    fd.append("active", language.active);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    };

    await axios
      .post("languages/", fd)
      .then((data) => {
        console.log(data);
        toast.success("Language has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      }).finally(() => {
        setSubmitting(false);
      })}
  };

  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Add Language</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={imageDataChange} />
        <input
          type="text"
          required
          title="This field is required"
          placeholder="language Name"
          onChange={languageName}
        ></input>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="language Code"
          onChange={languageCode}
        ></input>
        <input
            type="text"
            required
            title="This field is required"
            placeholder="Country Code"
            onChange={countryCode}
        ></input>
        <select required title="This field is required" onChange={isactive}>
          <option value="" disabled selected>
            Select
          </option>
          <option value={0}>Passive</option>
          <option value={1}>Active</option>
        </select>
        <button type="submit" className="buttonAction" disabled={submitting}>Add language</button>
      </form>
    </div>
  );
};

export default AddLanguages;
