import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './users.scss';
import axios from '../../axios'
import Search from "../Search/Search";
import ReactPaginate from "react-paginate";
import Loader from "../Loader/Loader";
import {toast} from "react-toastify";
import ApproveModal from './approvalsModal';
import BussinesModal from './businessInfo';


const Approvals = () => {
    const [users, setusers] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenApproval, setIsOpenApproval] = useState(false);
    const [isOpenBusiness, setIsOpenBusiness] = useState(false);
    const [dataToModal, setDataToModal] = useState(null);
    const [pointId, setPointId] = useState();
    // pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayUsers = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);

    const changePage = ({selected}) => {
        console.log(selected)
        setPageNumber(selected);
    };

    const getData = () =>{
        axios.get('/point/allPending').then((res) => {
            setusers(res?.data);
            setFiltered(res?.data)
            setIsLoading(false);
        }).catch((err) => {
            toast.error('Something went wrong!')
            console.log(err)
        });
    }

    const handleModalData = (data) => {
        setIsOpenApproval(true);
        setDataToModal(data);
      };

    const handleBusinessModalData = (data) => {
        setIsOpenBusiness(true);
        setPointId(data?.id);
      };

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
    {isOpenApproval === true && <ApproveModal isOpen={isOpenApproval} getData={()=>getData()} handleModalClose={() => { setIsOpenApproval(false)}} userData={dataToModal} />}
    {isOpenBusiness === true && <BussinesModal isOpen={isOpenBusiness} handleModalClose={() => {setIsOpenBusiness(false)}} point_id={pointId} />}
        <div className='users p-5 text-left'>
            <div className='page-title'>
                <h1>Point Approvals</h1> <br/>
            </div>
            <div className='row search-add'>
            <Search data={users} filtered={setFiltered} setPageNumber={setPageNumber}/>
            </div>
            <div className='row'>
            <div className='row list-description mb-2'>
                                    <div className='col'>Id</div>
                                    <div className='col'>Name</div>
                                    <div className='col'>City</div>
                                    <div className='col'>Country</div>
                                    <div className='col'>Status</div>
                                    <div className='col'>Info</div>
                                    <div className='col hideme'>Activate</div>
                                </div>
                {
                    isLoading ? <Loader/> :
                        displayUsers = filtered?.slice
                        (pagesVisited, pagesVisited + productsPerPage)?.map
                        (filtered => {
                            return (<div className='listimi showme' key={filtered.id}>
                                    <div className='row '>
                                        <div className='col'>{filtered.id} </div>
                                        <div className='col'>{filtered.name} </div>
                                        <div className='col'>{filtered?.city?.name} </div>
                                        <div className='col'>{filtered?.country?.name} </div>
                                        <div className='col'>Pending</div>
                                        <div className="col">
                                        <i className='fa fa-eye mr-3' onClick={() => handleBusinessModalData(filtered)}></i>
                                        </div>
                                        <div className='col hideme'>
                                        <i className='fa fa-edit' onClick={() => handleModalData(filtered)}></i>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    {(users.length >= productsPerPage) ?
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        /> : ''
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default Approvals;