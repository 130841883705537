import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios";

const DeleteCity = (props) => {
  const [city, addCity] = useState({});

  // const stateName = e => {
  //     console.log(e)
  //     const ud = Object.assign({}, city);
  //     ud.country_id = e.target.value
  //     addCity(ud);
  // }

  // const cityName = e => {
  //     console.log(e)
  //     const ud = Object.assign({}, city);
  //     ud.name = e.target.value
  //     addCity(ud);
  // }

  useEffect(() => {
    axios
      .get("cities/id?id=" + props.match.params.id)
      .then((data) => {
        addCity(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("cities/?id=" + city.id)
      .then((data) => {
        toast.success("City has been deleted successfully!");
        window.history.back();
      })
      .catch((err) => console.log(err));
  };
  console.log(city);
  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Delete City</h1>
      </div>
      <div className="row">
        By clicking delete button you agree to delete city with name {city.name}
      </div>
      <form onSubmit={handleSubmit}>
        <select
          required
          title="This field is required"
          value={city.country_id}
          disabled
        >
          <option value={null} disabled>
            Selekto
          </option>
          <option value={1}>Kosove</option>
        </select>
        <input
          type="text"
          required
          title="This field is required"
          placeholder="City Name"
          value={city.name}
          disabled
        ></input>
        <button type="submit">Delete City</button>
      </form>
    </div>
  );
};
export default DeleteCity;
