import React, {useEffect,useState} from 'react';
import { toast } from "react-toastify";
import axios from '../../axios';

const DeleteLabel = (props) => {

const [labels, addlabels] = useState({});

useEffect(()=>{

    axios.get('labels/id?id=' + props.match.params.id)
         .then(    (data) => {
            addlabels(data.data)
        })
         .catch(err =>  console.log(err))
},[])

const handleSubmit = (e) => {
    e.preventDefault();
    axios.delete('labels/?id='+ labels.id )
        .then(
            (data) => {
                toast.success("Label has been deleted successfully!");
               window.history.back();
            }
        )
        .catch(err => console.log(err));
}
    console.log(labels)
    return(
        <div className='editcities p-5 text-left'>
            <div className='row'><h1>Delete labels</h1></div>
            <div className='row'>By clicking delete button you agree to delete label with name {labels.name}</div>
            <form onSubmit={handleSubmit}>
          
                <input type='text' required
                       title="This field is required" placeholder='labels Name'  value={labels.name} disabled></input>
                <button type='submit'>Delete Label</button>
            </form>
        </div>

    )
}
export default DeleteLabel;
